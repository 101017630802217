import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const gameVuex = createNamespacedHelpers("game");
const userVuex = createNamespacedHelpers("user");
import { Sprite, Text, Graphics, Assets, NineSlicePlane } from "pixi.js";
const ratio = 0.82;
const scrollWidth = parseInt(_v.screenWidth * ratio);
const scrollHeight = _v.screenHeight - 60 - 50 - 20;
const scrollItemWidth = scrollWidth / 2;
const scrollItemHeight = 200;
await Assets.loadBundle("market");
// await _v.m_loadMinerAnimal("/assets/animation", "chaigong_1", 10, "idle");
// await _v.m_loadMinerAnimal("/assets/animation", "chaigong_2", 10, "idle");
// await _v.m_loadMinerAnimal("/assets/animation", "chaigong_3", 10, "idle");
// await _v.m_loadMinerAnimal("/assets/animation", "chaigong_4", 10, "idle");
const isLoading = _v.c_listLoading();
export default {
  name: "miner",
  inject: ["closePage", "setPageRecruitShow"],
  computed: {
    ...gameVuex.mapState(["gameApp", "minerList", "minerListPage", "minerListTotal", "selectMinerInfo"]),
    ...userVuex.mapState(["token", "accountInfo"]),
    isRender() {
      return this.$parent.pageMinerShow;
    }
  },
  watch: {
    isRender: {
      async handler(bool) {
        if (bool) {
          this.initScreen();
          await this.getMinerList();
          this.loadMinerList();
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      currentScreen: null,
      pageDom: null,
      isRenderPage: true
    };
  },
  async beforeDestroy() {
    await this.m_clearCurrentScreen(this.currentScreen);
  },
  methods: {
    ...gameVuex.mapActions(["getMinerList"]),
    ...gameVuex.mapMutations(["setMinerListPage", "setSelectMinerInfo"]),
    setListLoading(bool) {
      const scrollDom = _v[`page_miner`].content.getByID("miner_list_scroll");
      if (bool) {
        isLoading.show();
        scrollDom.visible = false;
      } else {
        isLoading.hide();
        scrollDom.visible = true;
      }
    },
    initScreen() {
      this.$nextTick(async () => {
        await this.setScreen();
      });
    },
    async setScreen() {
      if (!_v[`page_miner`]) {
        _v[`page_miner`] = this.m_pageDom(this.renderDom());
        _v[`page_miner`].visible = false;
        this.gameApp.stage.addChild(_v[`page_miner`]);
      }
      _v[`page_miner`].visible = true;
      _v[`page_miner`].zIndex = this.gameApp.stage.children.length + 1;
      this.pageDom = _v[`page_miner`];
      this.setZindex();
      this.setListLoading(true);
    },
    setZindex() {
      this.pageDom.sortableChildren = true;
      const miner_head = this.pageDom.getChildByID("miner_head");
      const miner_list = this.pageDom.getChildByID("miner_list");
      const miner_page = this.pageDom.getChildByID("miner_page");
      const miner_btn_back = this.pageDom.getChildByID("miner_btn_back");
      miner_page.sortableChildren = true;
      miner_head.zIndex = 2;
      miner_list.zIndex = 1;
      miner_btn_back.zIndex = 3;
    },
    loadMinerList() {
      if (!this.minerList) return;
      const scrollDom = _v[`page_miner`]?.content.getByID("miner_list_scroll").content;
      if (!scrollDom) return;
      scrollDom.createContent(this.minerListDom());
      scrollDom.removeContent(scrollDom.children.entries().next().value[0]);
      const miner_empty = _v[`page_miner`].content.getByID("miner_empty");
      if (this.minerList.length <= 0) {
        miner_empty.visible = true;
      } else {
        miner_empty.visible = false;
      }
      const miner_list_page = _v[`page_miner`].content.getByID("miner_list_page");
      const miner_list_page_main = _v[`page_miner`].content.getByID("miner_list_page_main").content;
      if (this.minerListTotal > this.minerListPage.perPage) {
        miner_list_page.visible = true;
        miner_list_page_main.createContent(this.c_page(this.minerListTotal, page => {
          this.setListLoading(true);
          this.setMinerListPage({
            page
          });
          (async _page => {
            await this.getMinerList();
            this.loadMinerList();
            this.setListLoading(false);
          })(page);
        }, this.minerListPage));
        miner_list_page_main.removeContent(miner_list_page_main.children.entries().next().value[0]);
      } else {
        miner_list_page.visible = false;
      }
      this.setListLoading(false);
    },
    minerItemDom(item, index) {
      return {
        id: "miner_item",
        content: {
          miner_item_bg: {
            content: this.sf_minerItemBg({
              width: scrollItemWidth * 4 - 20,
              height: scrollItemHeight * 4
            }),
            styles: globalStyles.miner_item_bg
          },
          miner_item_name: {
            content: `${this.setMiner(item.star, "name")}`,
            styles: globalStyles.miner_item_name
          },
          miner_item_info: {
            content: {
              miner_item_info_bg: {
                content: this.sf_minerItemElementBg({
                  width: scrollItemWidth * 4 - 130,
                  height: 90
                }),
                styles: globalStyles.miner_item_info_bg
              },
              miner_item_star: {
                content: this.starList(item),
                styles: globalStyles.miner_item_star
              },
              miner_item_level: {
                content: `Lv.${item.level}`,
                styles: globalStyles.miner_item_level
              }
            },
            styles: globalStyles.miner_item_info
          },
          miner_item_mine: {
            content: this.m_setSpineAnmationsSprite(`${this.setMiner(item.star, "img")}-idle`, {
              loop: true,
              isPlay: true,
              animationSpeed: 0.25
            }),
            content: this.m_setSpineAnmationsSprite(`${this.setMiner(item.star, "img")}-idle`, {
              loop: true,
              isPlay: true,
              animationSpeed: 0.25
            }),
            styles: globalStyles.miner_item_mine
          },
          miner_item_tag: item.status == 1 && item.position !== 0 ? {
            content: item.status == 1 ? "Mining" : " ",
            styles: globalStyles.miner_item_tag
          } : "",
          miner_item_id: {
            content: `ID: ${item.id}`,
            styles: globalStyles.miner_item_id
          }
        },
        styles: globalStyles.miner_item
      };
    },
    minerListDom() {
      return this.c_scrollBox(this.minerList.map((it, idx) => {
        return this.m_btn(this.minerItemDom(it, idx), () => {
          this.onMinerItem(it, idx);
        }, {
          width: scrollWidth / 2,
          height: scrollItemHeight
          // gsap: true,
          // init: 0.98,
          // result: 1,
        });
      }), {
        width: scrollWidth,
        height: scrollHeight,
        vertPadding: 60
      });
    },
    starList(item) {
      let list = [];
      for (let i = 0; i < item.star; i++) {
        list.push({
          content: Sprite.from(this.m_getRes("element").textures["icon-star.png"]),
          styles: globalStyles.miner_item_star_label
        });
      }
      return list;
    },
    renderDom() {
      return {
        content: {
          miner_page: {
            content: {
              miner_bg: {
                content: this.m_tilingSprite("bg5.jpg")
              },
              miner_btn_back: {
                content: this.c_btnBack(() => {
                  _v[`page_miner`].visible = false;
                  this.$parent.pageMinerShow = false;
                })
              },
              miner_head: {
                content: {
                  miner_head_bg: {
                    content: this.sf_popTitleBg({
                      width: this.screenWidth * 4 - 100,
                      height: 250
                    })
                  },
                  miner_title: {
                    content: {
                      miner_title_bg: {
                        content: Sprite.from(this.m_getRes("base").textures["title-bg.png"])
                      },
                      miner_title_text: {
                        content: this.$lang("MINER")
                      }
                    }
                  }
                }
              },
              miner_list: {
                content: {
                  miner_list_bg: {
                    content: this.sf_marketGoods({
                      width: this.screenWidth * 4 - 140,
                      height: this.screenHeight * 4 - 380
                    })
                  },
                  common_loading: isLoading.render(),
                  miner_list_scroll: {
                    content: " "
                  },
                  miner_list_page: {
                    content: {
                      miner_list_page_main: {
                        content: " "
                      }
                    }
                  },
                  miner_empty: {
                    content: this.c_empty(this.$lang("No miners, go recruit them"), {
                      isBtn: true,
                      btnText: this.$lang("Go Recruit")
                    }, () => {
                      this.closePage();
                      this.setPageRecruitShow = true;
                    }),
                    styles: globalStyles.miner_empty
                  }
                }
              }
            }
          }
        },
        globalStyles
      };
    },
    onMinerItem(item, index) {
      this.$parent.pageMinerShow = false;
      _v[`page_miner`].visible = false;
      this.setSelectMinerInfo(item);
      this.$parent.pageMinerDetailShow = true;
      this.$parent.pageMinerEntrance = true;
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (!_v[`page_miner`]) {
        _v[`page_miner`] = this.m_pageDom(this.renderDom());
        this.gameApp.stage.addChild(_v[`page_miner`]);
      }
      _v[`page_miner`].visible = false;
      _v[`page_miner`].zIndex = this.gameApp.stage.children.length + 1;
      this.pageDom = _v[`page_miner`];
      this.setZindex();
    });
  }
};
const globalStyles = {
  miner_page: {
    position: "leftTop",
    width: _v.screenWidth,
    height: _v.screenHeight,
    maxWidth: _v.screenWidth,
    maxHeight: _v.screenHeight,
    fontSize: 0,
    overflow: "hiddren"
  },
  miner_bg: {
    maxWidth: "100%",
    position: "leftBottom"
  },
  miner_coin: {
    position: "topRight",
    marginTop: 15
  },
  miner_head: {
    position: "centerTop",
    width: "100%",
    zIndex: 2,
    height: 50,
    marginTop: 60
  },
  miner_head_bg: {
    maxWidth: "100%",
    scale: 0.25,
    position: "center"
  },
  miner_title: {
    position: "centerTop",
    marginTop: -18
  },
  miner_title_bg: {
    position: "centerTop",
    scale: 0.26
  },
  miner_title_text: {
    marginTop: 22,
    marginLeft: 2,
    position: "centerTop",
    ..._v.fs_fontStyle({
      fz: 18
    })
  },
  miner_list: {
    position: "centerTop",
    marginTop: 70,
    height: "100%"
  },
  miner_list_bg: {
    position: "centerTop",
    scale: 0.25
  },
  miner_list_scroll: {
    position: "centerTop"
  },
  miner_list_page: {
    // maxHeight: 30,
    position: "centerTop",
    width: scrollWidth - 5,
    background: "#364254",
    marginTop: scrollHeight - 25,
    visible: false
  },
  miner_list_page_main: {
    marginTop: 8,
    position: "center"
  },
  miner_item: {
    // anchorX: 0.5,
    // minWidth: "100%",
  },
  miner_item_bg: {
    position: "center",
    scale: 0.25
  },
  miner_item_mine: {
    marginTop: 15,
    scale: 0.13,
    marginLeft: 35,
    height: 60,
    width: "100%"
  },
  miner_item_id: {
    width: "100%",
    position: "centerBottom",
    marginBottom: 15,
    textAlign: "center",
    fontSize: 13,
    color: "#11131e",
    fontFamily: "pFont"
  },
  miner_item_empty: {
    position: "center",
    width: 0,
    maxWidth: 0,
    height: 0,
    maxHeight: 0,
    overflow: "hidden"
  },
  miner_item_name: {
    width: "100%",
    textAlign: "center",
    position: "centerTop",
    marginTop: 120,
    ..._v.fs_fontStyle({
      fz: 16,
      st: 1,
      ds: 1
    })
  },
  miner_item_tag: {
    position: "leftTop",
    background: "#232b36",
    color: "#676b71",
    fontSize: 13,
    fontFamily: "pFont",
    marginLeft: 15,
    paddingLeft: 6,
    paddingRight: 6,
    borderRadius: 30,
    marginTop: 10
  },
  miner_title_name_label: {},
  miner_item_info: {
    position: "centerTop",
    marginTop: 145,
    width: "100%",
    height: 30,
    overflow: "hidden"
  },
  miner_item_info_bg: {
    scale: 0.25,
    position: "centerTop"
  },
  miner_item_star: {
    marginTop: -1,
    position: "left",
    marginLeft: 25
  },
  miner_item_star_label: {
    scale: 0.2,
    marginTop: 5,
    width: 15
    // marginTop: 2,
  },

  miner_item_level: {
    position: "right",
    marginTop: 1,
    marginRight: 25,
    ..._v.fs_fontStyle({
      fz: 15,
      st: 1,
      ds: 1
    })
  },
  miner_empty: {
    visible: false
  }
};