import { Assets, Sprite, Text } from "pixi.js";
window["loadingPage"] = null;
let timer = 0;
let loadingText = null;
let errorText = null;
let versionText = null;
let totalResNum = 166

export const gameLoading = async (app, dbHandler) => {
  const width = _v.screenWidth - 160;
  Assets.addBundle("preload", {
    loading_bg1: "/assets/preload/loading_bg1.jpg",
    loading_logo: "/assets/preload/loading_logo.png",
  });
  await Assets.loadBundle("preload");
  // await dbHandler.loadAndCacheAsset("loading_bg", "/assets/preload/loading_bg.jpg");
  // await dbHandler.loadAndCacheAsset("loading_logo", "/assets/preload/loading_logo.png");
  const { progressBar, progressText } = _v.c_progress({
    width: width,
    height: 20,
    radio: 60,
    progress: 100,
  });
  loadingText = new Text("", {
    fontSize: 16,
    fill: "#fff",
    fontFamily: "pFont",
    textAlign: "center",
    stroke: "#11131e",
    strokeThickness: 2,
    lineJoin: "round",
    miterLimit: 100,
    dropShadow: true,
    dropShadowAngle: 90,
    dropShadowDistance: 1,
  });
  versionText = new Text("Version 1.0", {
    fontSize: 13,
    fill: "#fff",
    textAlign: "center",
    fontFamily: "pFontM",
  });
  errorText = new Text("", {
    fontSize: 13,
    fill: "#f53f3f",
    textAlign: "center",
    fontFamily: "pFont",
    // wordWrap: true,
    // wordWrapWidth: _v.screenWidth / 1.5,
  });
  const progress = progressBar;
  const progress_text = progressText;
  const globalStyles = {
    loading: {
      position: "leftTop",
      width: _v.screenWidth,
      height: _v.screenHeight,
      maxWidth: _v.screenWidth,
      maxHeight: _v.screenHeight,
    },
    bg: {
      maxWidth: "100%",
      position: "leftBottom",
    },
    logo: {
      scale: 0.22,
      marginTop: 70,
      position: "centerTop",
    },

    progress: {
      width: width,
      height: 45,
      marginTop: 240,
      position: "centerTop",
      // marginBottom: 60,
      visible: true,
    },
    progress_bar: {
      position: "center",
      width: "100%",
      height: "100%",
    },
    progpress_info: {
      position: "centerBottom",
      width: "100%",
      textAlign: "center",
      // marginBottom: -20,
    },
    error: {
      width: _v.screenWidth - 60,
      // height: 20,
      position: "centerBottom",
      marginBottom: 60,
      visible: false,
      textAlign: "center",
      background: "#111111",
      fontFamily: "pFont",
      // textBaseline: "middle",
      paddingTop: 10,
      paddingBottom: 10,
      borderRadius: 10,
    },
    error_info: {
      position: "center",
      width: "100%",
      textAlign: "center",
    },
    version: {
      position: "centerBottom",
      fontSize: 14,
      height: 20,
      color: "#fff",
      width: _v.screenWidth,
      textAlign: "center",
      marginBottom: 25,
    },
  };
  const setDom = () => {
    return {
      content: {
        loading: {
          content: {
            bg: {
              content: Sprite.from("loading_bg1"),
              styles: globalStyles.bg,
            },
            logo: {
              content: Sprite.from("loading_logo"),
              styles: globalStyles.logo,
            },
            progress: {
              content: {
                progress_bar: {
                  content: progress,
                  styles: globalStyles.progress_bar,
                },
                progpress_info: {
                  content: loadingText,
                  styles: globalStyles.progpress_info,
                },
              },
              styles: globalStyles.progress,
            },
            error: {
              content: {
                error_info: {
                  content: errorText,
                  styles: globalStyles.error_info,
                },
              },
              styles: globalStyles.error,
            },
            version: {
              content: versionText,
              styles: globalStyles.version,
            },
          },
          styles: globalStyles.loading,
        },
      },
    };
  };

  return {
    render: () => {
      window["loadingPage"] = _v.m_pageDom(setDom());
      app.stage.addChild(window["loadingPage"]);
    },
    updateVersion: (version) => {
      versionText.text = `Version ${version}`;
    },
    update: (progress, content) => {
      let _content = content || "";
      loadingText.text = _content;
      timer += (progress / 100) * (100/totalResNum);
      progressBar.progress = `${timer >= 100 ? 100 : parseInt(timer)}`;
      progress_text.text = `${timer >= 100 ? 100 : parseInt(timer)}%`;
    },
    changeInfo: (content) => {
      let _content = content || "";
      loadingText.text = _content;
    },
    remove: () => {
      if (window["loadingPage"]) {
        window["loadingPage"].destroy();
        app.stage.removeChild(window["loadingPage"]);
      }
    },
    error: (content) => {
      if (window["loadingPage"]) {
        const loadingPage = window["loadingPage"].content;
        const progressDom = loadingPage.getByID("progress");
        const errorDom = loadingPage.getByID("error");
        progressDom.visible = false;
        errorText.text = content;
        errorDom.visible = true;
      }
    },
  };
};
