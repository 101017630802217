import Vue from "vue";
import vailcode from "@utils/errcode";
import cookie from "js-cookie";
import querystring from "querystring";
import { resolve } from "path";

export default {
  namespaced: true,
  state: {
    token: "",
    gameInfo: {},
    accountInfo: {
      store_max: 0
    },
    calcCointimer: null,
    coinInfo: {
      produceTotalNum: 0,
      colletTotalNum: 0,
      collecting: false   //正在请求时， 不允许多次调用
    },
  },
  mutations: {
    setToken: (state, payload) => {
      state.token = payload;
    },

    setAccountInfo: (state, payload) => {
      state.accountInfo = {};
      for (const key in payload) {
        _v.$set(state.accountInfo, key, payload[key]);
      }
    },
    setGameInfo: (state, payload) => {
      state.gameInfo = {};
      for (const key in payload) {
        _v.$set(state.gameInfo, key, payload[key]);
      }
    },
    setCalcCoinInfoTimer: (state, payload) => {
      state.calcCointimer = payload;
    },
    clearCalcCoinInfoTimer: (state, payload) => {
      clearInterval(state.calcCointimer);
    },
  },
  actions: {
    getLoginInfo: ({ state, commit, dispatch, rootState }, [data, startParam = null, callback]) => {
      return new Promise(async (resolve, reject) => {
        try {
          try {
            console.log("ref", startParam )
          }catch(e){
            console.log("ref", e.message)
          }
          console.log("_v.$http", _v.$http);
          let refCode;
          if(startParam){
             refCode = startParam.replace("ref_","")
          }
          const res = await _v.$http.post("/auth/login", { initData: data, refCode: refCode });
          vailcode(res, () => {
            commit("setToken", res.data.token);
            const game = res.data.game;
            commit("game/setMineList", game.mineList, { root: true });
            commit("game/setDiamondList", game.diamondPackage, { root: true });
            commit("game/setBotInfo", res.data.bot, { root: true });
            console.log("rootState.game.botInfo", res.data.bot, rootState.game.botInfo);
            let gameInfo = {};
            for (const key in game) {
              if (key != "diamondPackage" && key !== "mineList") {
                gameInfo[key] = game[key];
              }
            }
            commit("setGameInfo", gameInfo);
            console.log("xxxxx", state.gameInfo);
            resolve(res);
          }, ()=> {
            resolve(null)
          });
        } catch (err) {
          resolve(null)
          console.log("getLoginInfo error: ", err);
        }
      });
    },
    calcCoin: ({ state, commit }, data) => {
      commit("clearCalcCoinInfoTimer", null);
      //重置coinInfo从服务端的store_coin时， 已领的本地暂存清空
      state.coinInfo.produceTotalNum = data.store_coin;
      state.coinInfo.colletTotalNum = 0;
      const time = 1;
      const calcTotalCoin = () => {
        const max = state.accountInfo.store_max;
        let num = Number(state.coinInfo.produceTotalNum + data.power);
        if (num >= max) {
          num = max;
        }
        state.coinInfo.produceTotalNum = num;
      };
      commit(
        "setCalcCoinInfoTimer",
        setInterval(() => {
          calcTotalCoin();
        }, time * 1000)
      );
    },

    // 收集金币统一改成该函数
    getAccountInfo: async ({ state, commit, dispatch }, obj) => {
      return new Promise(async(resolve, reject)=> {
        try {
          if (!obj) {
              //该api同时只能有一个请求
              if(!state.coinInfo.collecting){
                state.coinInfo.collecting = true;
                let amount = state.coinInfo.colletTotalNum; //提交当下的收集的金币
                if(amount>0){
                  let max = Math.floor(state.accountInfo.store_max)
                   //验证是否超出了金币仓库
                   if(amount > max){
                       amount = max;
                   }
                }
                try{
                  const res = await _v.$http.post("/account/info", { amount });
                  vailcode(res, () => {
                    state.coinInfo.colletTotalNum -= amount; //提交成功，减掉当前客户端的数额， 未成功不更新
                    const data = res.data;
                    data.coinMarketOpen = false
                    commit("setAccountInfo", data);
                    dispatch("calcCoin", data);
                    resolve(res)
                  });
                  state.coinInfo.collecting = false;
                }
                catch (e) {
                  state.coinInfo.collecting = false;
                }
              }
          } else {
            commit("setAccountInfo", obj);
            dispatch("calcCoin", obj);
            resolve(null)
          }
        } catch (err) {
          console.log("getAccountInfo error: ", err);
          resolve(null)
        }
      })
    },
  },
  getters: {},
};
