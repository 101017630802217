import { Point } from "pixi.js";
import gsap from "gsap";
export default {
  anim_collectCoin: (pageDom, collectDom, storeDom, callback) => {
    const collectContainer = pageDom.content.getByID(collectDom);
    const storeContainer = pageDom.content.getByID(storeDom);
    let coinsToMove = [];
    let moveSpeed = 25;
    let allCoinsArrived = false;
    const waitDistance = 35; // 配置项，等待的距离

    function moveCoins() {
      coinsToMove = collectContainer.children.slice(); // 获取所有金币
      allCoinsArrived = false; // 重置所有金币到达的标志
      for (let i = 0; i < coinsToMove.length; i++) {
        const coin = coinsToMove[i];
        coin.moving = false;
        coin.distanceMoved = 0;
      }
      if (coinsToMove.length > 0) {
        coinsToMove[0].moving = true;
      }
    }

    function animate(delta) {
      if (coinsToMove.length > 0) {
        for (let i = 0; i < coinsToMove.length; i++) {
          const coin = coinsToMove[i];
          if (!coin.targetX) {
            // 获取存储容器的全局位置
            const globalPosition = storeContainer.toGlobal(new Point(0, 0));
            // 获取金币的全局位置
            const coinGlobalPosition = collectContainer.toGlobal(new Point(coin.x, coin.y));
            // 设置目标位置
            coin.targetX = globalPosition.x + Math.random() * (storeContainer.width - coin.width);
            coin.targetY = globalPosition.y;
            // 设置初始位置
            coin.startX = coinGlobalPosition.x;
            coin.startY = coinGlobalPosition.y;
          }

          if (coin.moving) {
            const dx = coin.targetX - coin.startX;
            const dy = coin.targetY - coin.startY;
            const distance = Math.sqrt(dx * dx + dy * dy);

            if (distance > moveSpeed) {
              const angle = Math.atan2(dy, dx);
              coin.startX += Math.cos(angle) * moveSpeed * delta;
              coin.startY += Math.sin(angle) * moveSpeed * delta;
              // 更新金币在收集容器中的位置
              const localPosition = collectContainer.toLocal(new Point(coin.startX, coin.startY));
              coin.x = localPosition.x;
              coin.y = localPosition.y;
              coin.distanceMoved += moveSpeed * delta;

              // 检查是否达到启动下一个金币的距离
              if (coin.distanceMoved >= waitDistance && i + 1 < coinsToMove.length) {
                coinsToMove[i + 1].moving = true;
              }
            } else {
              // 当金币到达目标位置时，将其隐藏
              coin.visible = false;
              coin.moving = false;
              allCoinsArrived = true;
            }
          }
        }

        // 检查所有金币是否到达目标位置
        const allCoinsStopped = coinsToMove.every((coin) => !coin.moving);

        if (allCoinsStopped && allCoinsArrived) {
          // batchRemoveCoins();
          coinsToMove = [];
          allCoinsArrived = false;
          _v.m_removeDom(animate, { key: "collectCoin" });
          typeof callback == "function" && callback();
        }
      }
    }
    function batchRemoveCoins() {
      const batchSize = 5; // 每批移除的金币数量
      let batchIndex = 0;

      function removeNextBatch() {
        const startIndex = batchIndex * batchSize;
        const endIndex = Math.min(startIndex + batchSize, collectContainer.children.length);

        for (let i = startIndex; i < endIndex; i++) {
          collectContainer.removeChildAt(startIndex);
        }

        batchIndex++;

        if (startIndex < collectContainer.children.length) {
          requestAnimationFrame(removeNextBatch);
        }
      }

      removeNextBatch();
    }

    moveCoins();
    _v.m_updateDom(animate, { key: "collectCoin" });
  },
  anim_pop: (pop, callback, option) => {
    try{
      pop.visible = true;
      // console.log("pop, callback, option", pop, callback, option);
      const pop_main = pop.content.getByID("pop_main")
      if(pop_main) {
        pop_main.y = _v.screenHeight;
        // console.log("pop_main.y=======", pop_main.y);
        gsap.to(pop_main, {
          duration: 0.3, // 动画持续时间
          y: option?.y?option.y: 0, // 移动到视窗的中间
          alpha: 1, // 透明度变为 1
          ease: "power2.out", // 使用缓动效果
          onUpdate: ()=> {
            // console.log("pop_main", pop_main.y);
          },
          onComplete: () => {
            typeof callback == "function" && callback();
            // console.log("Animation complete");
          },
        });
      }
    }catch(err) {}
  },
};
