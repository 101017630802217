import { createNamespacedHelpers } from "vuex";
const gameVuex = createNamespacedHelpers("game");
const userVuex = createNamespacedHelpers("user");
import { Sprite, Text, Graphics, Assets, NineSlicePlane } from "pixi.js";
export default {
  computed: {
    ...gameVuex.mapState(["gameApp", "selectMinerInfo"]),
    ...userVuex.mapState(["accountInfo", "coinInfo"])
  },
  name: "popSetting",
  watch: {
    "$parent.popSettingShow": {
      async handler(bool) {
        if (bool) {
          this.initPop();
          this.setBgm();
          this.setEffect();
          this.loadSettingInfo();
          await this.unsubscribeTonConnectStatus();
        } else {
          this.uiAddress = null;
          this.bindAddress = null;
          this.unsubscribe && this.unsubscribe();
        }
      },
      deep: true,
      immediate: true
    },
    "tonWallet.connected": {
      handler(bool) {
        console.log("tonWallet.connected=====", this.tonWallet.connected);
        if (bool) {}
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      popDom: null,
      unsubscribe: null,
      popBindWalletShow: false,
      uiAddress: null,
      bindAddress: null,
      initPopBgDom: null,
      popBindWalletDom: null
    };
  },
  methods: {
    ...gameVuex.mapActions(["bindWallet"]),
    ...userVuex.mapActions(["getAccountInfo"]),
    initPop() {
      if (!this.$parent.popSettingDom) {
        this.$parent.popSettingDom = this.m_popDom(this.renderDom());
        this.gameApp.stage.addChild(this.$parent.popSettingDom);
      }
      this.$parent.popSettingDom.visible = true;
      this.$parent.popSettingDom.zIndex = this.gameApp.stage.children.length + 10;
      this.$parent.popSettingDom.position.set(0, 0);
      this.setZindex();
      this.setWalletDom();
      this.loadSettingInfo();
      this.anim_pop(this.$parent.popSettingDom, null, {
        y: _v.screenHeight / 2 - 640 / 2
      });
    },
    setZindex() {
      if (!this.$parent.popSettingDom) {
        return;
      }
      const popDom = this.$parent.popSettingDom;
      const pop_main = popDom.getChildByID("pop_main");
      const pop_head = popDom.getChildByID("pop_head");
      const pop_con = popDom.getChildByID("pop_con");
      pop_main.sortableChildren = true;
      pop_head.zIndex = 2;
      pop_con.zIndex = 1;
    },
    loadSettingInfo() {
      const popDom = this.$parent.popSettingDom.content;
      if (popDom) {
        const pop_setting_item_text_name = popDom.getByID("pop_setting_item_text_name");
        pop_setting_item_text_name.children[0].text = `${this.accountInfo.firstname} ${this.accountInfo.lastname}`;
        const pop_setting_item_text_coin = popDom.getByID("pop_setting_item_text_coin");
        pop_setting_item_text_coin.children[0].text = `${(this.accountInfo.coin + this.coinInfo.colletTotalNum).toLocaleString("en-US")}`;
        const pop_setting_item_text_coin_sold = popDom.getByID("pop_setting_item_text_coin_sold");
        pop_setting_item_text_coin_sold.children[0].text = `${(this.accountInfo.free_coin + this.coinInfo.colletTotalNum).toLocaleString("en-US")}`;
        const pop_setting_item_text_diamond = popDom.getByID("pop_setting_item_text_diamond");
        pop_setting_item_text_diamond.children[0].text = `${this.accountInfo.diamond.toLocaleString("en-US")}`;
        const pop_setting_item_text_gme = popDom.getByID("pop_setting_item_text_gme");
        pop_setting_item_text_gme.children[0].text = `${this.accountInfo.gme.toLocaleString("en-US")}`;
        const pop_setting_main_1 = popDom.getByID("pop_setting_main_1")?.layout;
        pop_setting_main_1?.updateParents;
        const pop_setting_main_2 = popDom.getByID("pop_setting_main_2")?.layout;
        pop_setting_main_2?.updateParents;
      }
    },
    renderDom() {
      return {
        content: {
          pop_upgradeWarehouse: {
            content: {
              pop_mask: {
                content: this.c_createMask()
              },
              pop_main: {
                content: {
                  pop_head: {
                    content: {
                      pop_head_bg: {
                        content: this.sf_popTitleBg({
                          width: this.screenWidth * 4 - 100,
                          height: 250
                        })
                      },
                      pop_title: {
                        content: {
                          pop_title_bg: {
                            content: Sprite.from(this.m_getRes("base").textures["title-bg.png"])
                          },
                          pop_title_text: {
                            content: "SETTING"
                          }
                        }
                      },
                      pop_close: {
                        content: this.c_btnClose(() => {
                          this.$parent.popSettingDom.visible = false;
                          this.$emit("update:show", false);
                        }, {
                          maxWidth: 40
                        })
                      }
                    }
                  },
                  pop_con: {
                    content: {
                      pop_main_bg: {
                        content: {
                          pop_main_bg_label: {
                            content: this.sf_marketGoods({
                              width: this.screenWidth * 4 - 140,
                              height: 520 * 4
                            })
                          },
                          pop_setting_list: {
                            content: {
                              pop_setting_main_1: {
                                content: {
                                  pop_setting_item: {
                                    content: {
                                      pop_setting_item_icon: {
                                        content: Sprite.from(this.m_getRes("element").textures["icon-user.png"])
                                      },
                                      pop_setting_item_text_name: {
                                        content: `${this.accountInfo.firstname} ${this.accountInfo.lastname}`,
                                        styles: globalStyles.pop_setting_item_text
                                      }
                                    },
                                    styles: globalStyles.pop_setting_item
                                  }
                                },
                                styles: {
                                  ...globalStyles.pop_setting_main,
                                  ...globalStyles.pop_setting_main_1
                                }
                              },
                              pop_setting_item_title_1: {
                                content: {
                                  pop_setting_item_title_label: {
                                    content: "Account"
                                  }
                                },
                                styles: {
                                  ...globalStyles.pop_setting_item_title,
                                  ...globalStyles.pop_setting_item_title_1
                                }
                              },
                              pop_setting_main_2: {
                                content: {
                                  pop_setting_item_1: {
                                    content: {
                                      pop_setting_item_icon: {
                                        content: Sprite.from(this.m_getRes("element").textures["icon-coin.png"])
                                      },
                                      pop_setting_item_text_coin: {
                                        content: `${this.accountInfo.coin.toLocaleString("en-US")}`,
                                        styles: globalStyles.pop_setting_item_text
                                      }
                                    },
                                    styles: globalStyles.pop_setting_item
                                  },
                                  pop_setting_item_2: {
                                    content: {
                                      pop_setting_item_icon: {
                                        content: Sprite.from(this.m_getRes("element").textures["icon-mined-coin.png"])
                                      },
                                      pop_setting_item_text_coin_sold: {
                                        content: `${this.accountInfo.free_coin.toLocaleString("en-US")}`,
                                        styles: {
                                          ...globalStyles.pop_setting_item_text,
                                          ...globalStyles.pop_setting_item_text_coin_sold
                                        }
                                      },
                                      pop_setting_item_text_coin_question: {
                                        content: {
                                          pop_setting_item_text_coin_question_main: this.m_tooltip({
                                            content: Sprite.from(this.m_getRes("element").textures["icon-question1.png"])
                                          }, "Free Coins: Only gold coins mined\n by miners are salable to maintain market circulation.\n Coins from daily tasks, rewards,\n or purchases are not salable, only usable.")
                                        },
                                        styles: globalStyles.pop_setting_item_text_coin_question
                                      }
                                    },
                                    styles: globalStyles.pop_setting_item
                                  },
                                  pop_setting_item_3: {
                                    content: {
                                      pop_setting_item_icon: {
                                        content: Sprite.from(this.m_getRes("element").textures["icon-diamond.png"])
                                      },
                                      pop_setting_item_text_diamond: {
                                        content: `${this.accountInfo.diamond.toLocaleString("en-US")}`,
                                        styles: globalStyles.pop_setting_item_text
                                      }
                                    },
                                    styles: globalStyles.pop_setting_item
                                  },
                                  pop_setting_item_4: {
                                    content: {
                                      pop_setting_item_icon: {
                                        content: Sprite.from(this.m_getRes("element").textures["icon-gem.png"])
                                      },
                                      pop_setting_item_text_gme: {
                                        content: `${this.accountInfo.gme.toLocaleString("en-US")}`,
                                        styles: globalStyles.pop_setting_item_text
                                      }
                                    },
                                    styles: globalStyles.pop_setting_item
                                  }
                                },
                                styles: {
                                  ...globalStyles.pop_setting_main,
                                  ...globalStyles.pop_setting_main_2
                                }
                              },
                              pop_setting_item_title_2: {
                                content: {
                                  pop_setting_item_title_label: {
                                    content: "Wallet"
                                  },
                                  pop_setting_item_btn: {
                                    content: " ",
                                    styles: globalStyles.pop_setting_item_btn
                                  }
                                },
                                styles: {
                                  ...globalStyles.pop_setting_item_title,
                                  ...globalStyles.pop_setting_item_title_2
                                }
                              },
                              pop_setting_main_3: {
                                content: {
                                  pop_setting_item_4: this.m_btn({
                                    content: {
                                      pop_setting_item_icon: {
                                        content: Sprite.from(this.m_getRes("element").textures["icon-wallet.png"]),
                                        styles: globalStyles.pop_setting_item_icon
                                      },
                                      pop_setting_item_text: {
                                        content: " ",
                                        styles: {
                                          ...globalStyles.pop_setting_item_text,
                                          ...globalStyles.pop_setting_item_text_1
                                        }
                                      },
                                      pop_setting_item_btn_disconnect: {
                                        content: " ",
                                        styles: globalStyles.pop_setting_item_btn_disconnect
                                      }
                                    },
                                    styles: globalStyles.pop_setting_item
                                  }, () => {
                                    this.tonWalletConnect();
                                  })
                                },
                                styles: {
                                  ...globalStyles.pop_setting_main,
                                  ...globalStyles.pop_setting_main_3
                                }
                              },
                              pop_setting_main_4: {
                                content: {
                                  pop_setting_music: {
                                    content: {
                                      pop_setting_music_main: this.m_btn({
                                        content: {
                                          pop_setting_music_title: {
                                            content: "Music",
                                            styles: globalStyles.pop_setting_music_title
                                          },
                                          pop_setting_music_check: {
                                            content: {
                                              pop_setting_music_check_bg: {
                                                content: {
                                                  pop_setting_music_check_bg_main: {
                                                    content: Sprite.from(this.m_getRes("setting-music-bg")),
                                                    styles: globalStyles.pop_setting_music_check_bg_main
                                                  }
                                                },
                                                styles: globalStyles.pop_setting_music_check_bg
                                              },
                                              pop_setting_music_text: {
                                                content: {
                                                  pop_setting_music_text_off: {
                                                    content: "ON",
                                                    styles: globalStyles.pop_setting_music_text_off
                                                  }
                                                },
                                                styles: globalStyles.pop_setting_music_text
                                              },
                                              pop_setting_music_check_img: {
                                                content: {
                                                  pop_setting_music_check_img_main: {
                                                    content: Sprite.from(this.m_getRes("icon-music")),
                                                    styles: globalStyles.pop_setting_music_check_img_main
                                                  }
                                                },
                                                styles: globalStyles.pop_setting_music_check_img
                                              }
                                            },
                                            styles: globalStyles.pop_setting_music_check
                                          }
                                        },
                                        styles: globalStyles.pop_setting_music_main
                                      }, () => {
                                        this.onSettingMusic("music");
                                      })
                                    },
                                    styles: globalStyles.pop_setting_music
                                  },
                                  pop_setting_effect: {
                                    content: {
                                      pop_setting_music_main: this.m_btn({
                                        content: {
                                          pop_setting_music_title: {
                                            content: "Sound effects",
                                            styles: globalStyles.pop_setting_music_title
                                          },
                                          pop_setting_effect_check: {
                                            content: {
                                              pop_setting_music_check_bg: {
                                                content: {
                                                  pop_setting_effect_check_bg_main: {
                                                    content: Sprite.from(this.m_getRes("setting-music-bg")),
                                                    styles: globalStyles.pop_setting_music_check_bg_main
                                                  }
                                                },
                                                styles: globalStyles.pop_setting_music_check_bg
                                              },
                                              pop_setting_music_text: {
                                                content: {
                                                  pop_setting_effect_text_off: {
                                                    content: "ON",
                                                    styles: globalStyles.pop_setting_music_text_off
                                                  }
                                                },
                                                styles: globalStyles.pop_setting_music_text
                                              },
                                              pop_setting_music_check_img: {
                                                content: {
                                                  pop_setting_effect_check_img_main: {
                                                    content: Sprite.from(this.m_getRes("icon-effect")),
                                                    styles: globalStyles.pop_setting_music_check_img_main
                                                  }
                                                },
                                                styles: globalStyles.pop_setting_music_check_img
                                              },
                                              styles: globalStyles.pop_setting_music_check
                                            },
                                            styles: globalStyles.pop_setting_music_check
                                          }
                                        },
                                        styles: globalStyles.pop_setting_music_main
                                      }, () => {
                                        this.onSettingMusic("effect");
                                      })
                                    },
                                    styles: globalStyles.pop_setting_effect
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        },
        globalStyles
      };
    },
    setWalletDom() {
      if (!this.$parent.popSettingDom) {
        return;
      }
      const popDom = this.$parent.popSettingDom;
      const pop_setting_item_text = popDom.content.getByID("pop_setting_item_text").content;
      pop_setting_item_text.createContent(this.tonWallet.connected ? this.fmt_address(this.tonWallet.uiAddress, 12) : "Connect Wallet");
      pop_setting_item_text.removeContent(pop_setting_item_text.children.entries().next().value[0]);
      const pop_setting_item_btn_disconnect = popDom.content.getByID("pop_setting_item_btn_disconnect").content;
      this.uiAddress = this.tonWallet.uiAddress?.toLowerCase();
      this.bindAddress = this.accountInfo.wallet ? this.tonWallet.tranAddress(this.accountInfo.wallet).toLowerCase() : null;
      console.log("toLowerCase", this.uiAddress, this.bindAddress);
      if (this.tonWallet.connected) {
        pop_setting_item_btn_disconnect.createContent({
          pop_setting_item_btn_disconnect_img: this.m_btn({
            styles: globalStyles.pop_setting_item_btn_disconnect_img,
            content: Sprite.from(this.m_getRes("element").textures["btn-wallet-disconnect.png"])
          }, () => {
            this.onDisconnect();
          })
        });
      } else {
        pop_setting_item_btn_disconnect.createContent({
          content: " "
        });
        pop_setting_item_btn_disconnect.removeContent(pop_setting_item_btn_disconnect.children.entries().next().value[0]);
      }
      if (this.tonWallet.connected) {
        const btnContent = () => {
          if (!this.tonWallet.connected) {
            return " ";
          } else if (!this.bindAddress) {
            return {
              content: {
                pop_setting_item_btn_text: {
                  content: "Bind wallet",
                  styles: globalStyles.pop_setting_item_btn_text
                }
              },
              styles: globalStyles.pop_setting_item_btn_main
            };
          }
          //换绑
          else if (this.uiAddress !== this.bindAddress) {
            return {
              content: {
                pop_setting_item_btn_text: {
                  content: "Rebind Wallet",
                  styles: globalStyles.pop_setting_item_btn_text
                }
              },
              styles: {
                ...globalStyles.pop_setting_item_btn_main
              }
            };
          } else {
            return {
              content: " "
            };
          }
        };
        const pop_setting_item_btn = popDom.content.getByID("pop_setting_item_btn").content;
        pop_setting_item_btn.createContent({
          pop_setting_item_btn_main: this.m_btn(btnContent(), () => {
            if (!this.bindAddress) {
              return this.bindWalletFn();
            } else if (this.uiAddress != this.bindAddress) {
              this.popBindWalletShow = true;
            }
          })
        });
        pop_setting_item_btn.removeContent(pop_setting_item_btn.children.entries().next().value[0]);
      } else {
        const pop_setting_item_btn = popDom.content.getByID("pop_setting_item_btn").content;
        pop_setting_item_btn.createContent({
          content: " "
        });
        pop_setting_item_btn.removeContent(pop_setting_item_btn.children.entries().next().value[0]);
      }
    },
    async popBindSubmint() {
      // await this.tonWallet.disconnect(async () => {
      //   this.$refs.popBindWalletRef.closeFn();
      //   await this.tonWallet.openOrCloseTonModal(true);
      //   this.setWalletDom();
      // });
    },
    async bindWalletFn() {
      await this.bindWallet();
      await this.getAccountInfo();
      this.setWalletDom();
      this.$toasted.success(this.$lang("Binding Successful"));
    },
    async onDisconnect() {
      await this.tonWallet.disconnect(() => {
        this.setWalletDom();
        this.$toasted.success(this.$lang("Wallet Logout Successful!"));
      });
    },
    async tonWalletConnect() {
      await this.tonWallet.openOrCloseTonModal(true);
      this.setWalletDom();
    },
    unsubscribeTonConnectStatus() {
      try {
        const tonWallet = this.tonWallet;
        // console.log("666666666", tonWallet);
        const unsubscribe = tonWallet.ton.onStatusChange(async walletAndwalletInfo => {
          console.log("walletAndwalletInfo1111111", tonWallet.connected);
          if (tonWallet.connected && !this.accountInfo.wallet) {
            await this.bindWallet();
            await this.getAccountInfo();
          }
          tonWallet.getWalletInfo();
          this.$set(tonWallet, "wallet", walletAndwalletInfo);
          this.setWalletDom();
        });
        this.unsubscribe = unsubscribe;
      } catch (err) {
        console.log("unsubscribeTonConnectStatus :", err);
      }
    },
    setBgm() {
      const popDom = this.$parent.popSettingDom;
      const pop_setting_music_check = popDom.content.getByID("pop_setting_music_check");
      const pop_setting_music_check_bg_main = popDom.content.getByID("pop_setting_music_check_bg_main");
      const pop_setting_music_text_off = popDom.content.getByID("pop_setting_music_text_off");
      const pop_setting_music_check_img_main = popDom.content.getByID("pop_setting_music_check_img_main");
      if (window.isBgm) {
        pop_setting_music_check_bg_main.children[0].texture = this.m_getRes("setting-music-bg");
        pop_setting_music_text_off.children[0].text = "ON";
        pop_setting_music_text_off.x = pop_setting_music_check.width - 30;
        pop_setting_music_check_img_main.x = 0;
        if (!window["bgm"]) {
          _v.music_bgm(1);
        }
        localStorage.setItem("isBgm", "yes");
        window.isBgm = true;
      } else {
        pop_setting_music_check_bg_main.children[0].texture = this.m_getRes("setting-music-bg_no");
        pop_setting_music_text_off.children[0].text = "OFF";
        pop_setting_music_check_img_main.x = pop_setting_music_check.width - 10;
        pop_setting_music_text_off.x = 10;
        localStorage.setItem("isBgm", "no");
        window.isBgm = false;
        if (window["bgm"]) {
          window["bgm"].stop();
          window["bgm"].disconnect();
          window["bgm"] = null;
        }
      }
    },
    setEffect() {
      const popDom = this.$parent.popSettingDom;
      const pop_setting_effect_check = popDom.content.getByID("pop_setting_effect_check");
      const pop_setting_effect_check_bg_main = popDom.content.getByID("pop_setting_effect_check_bg_main");
      const pop_setting_effect_text_off = popDom.content.getByID("pop_setting_effect_text_off");
      const pop_setting_effect_check_img_main = popDom.content.getByID("pop_setting_effect_check_img_main");
      if (window.isEffect) {
        pop_setting_effect_check_bg_main.children[0].texture = this.m_getRes("setting-music-bg");
        pop_setting_effect_text_off.children[0].text = "ON";
        pop_setting_effect_text_off.x = pop_setting_effect_check.width - 30;
        pop_setting_effect_check_img_main.x = 0;
        _v.music_effect(0);
        window.isEffect = true;
        localStorage.setItem("isEffect", "yes");
      } else {
        pop_setting_effect_check_bg_main.children[0].texture = this.m_getRes("setting-music-bg_no");
        pop_setting_effect_text_off.children[0].text = "OFF";
        pop_setting_effect_check_img_main.x = pop_setting_effect_check.width - 10;
        pop_setting_effect_text_off.x = 10;
        window.isEffect = false;
        localStorage.setItem("isEffect", "no");
        if (window["effect"]) {
          window["effect"].stop();
          window["effect"].disconnect();
          window["effect"] = null;
        }
      }
    },
    onSettingMusic(type) {
      switch (type) {
        case "music":
          window.isBgm = !window.isBgm;
          this.setBgm();
          break;
        case "effect":
          window.isEffect = !window.isEffect;
          this.setEffect();
          break;
      }
    }
  }
};
const globalStyles = {
  pop_upgradeWarehouse: {
    width: _v.screenWidth,
    height: _v.screenHeight,
    position: "left"
  },
  pop_mask: {
    position: "left",
    width: "100%",
    height: "100%"
  },
  pop_main: {
    position: "center",
    width: "100%",
    height: 620,
    overflow: "hidden"
  },
  pop_head: {
    position: "centerTop",
    width: "100%",
    zIndex: 2,
    height: 50,
    marginTop: 80
  },
  pop_head_bg: {
    maxWidth: "100%",
    scale: 0.25,
    position: "center"
  },
  pop_title: {
    position: "centerTop",
    marginTop: -18
  },
  pop_title_bg: {
    position: "centerTop",
    scale: 0.26
  },
  pop_title_text: {
    marginTop: 22,
    marginLeft: 2,
    position: "centerTop",
    ..._v.fs_fontStyle({
      fz: 18,
      st: 2,
      ds: 1
    })
  },
  pop_close: {
    position: "rightTop"
  },
  pop_con: {
    position: "centerTop",
    marginTop: 70,
    width: _v.screenWidth,
    height: 540,
    overflow: "hidden"
  },
  pop_main_bg: {
    position: "center",
    width: "100%",
    height: "100%"
  },
  pop_main_bg_label: {
    scale: 0.25,
    position: "center"
  },
  pop_setting_list: {
    marginTop: 70,
    width: _v.screenWidth - 100,
    position: "centerTop"
  },
  pop_setting_item_title: {
    // marginTop: 6,
    height: 20,
    width: "100%",
    position: "centerTop"
  },
  pop_setting_item_title_1: {
    marginTop: 60
  },
  pop_setting_item_title_2: {
    marginTop: 280
  },
  pop_setting_item_title_label: {
    height: "100%",
    position: "left",
    fontSize: 14,
    color: "#fff",
    fontFamily: "pFont",
    position: "leftCenter"
  },
  pop_setting_item_btn: {
    height: 25,
    position: "centerRight"
  },
  pop_setting_item_btn_main: {
    height: "100%",
    position: "centerRight",
    background: "#34c1f4",
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 25
  },
  pop_setting_item_btn_main_error: {
    background: "#f53f3f"
  },
  pop_setting_item_btn_main_success: {
    background: "#00b42a"
  },
  pop_setting_item_btn_icon: {
    scale: 0.25,
    width: 15,
    marginTop: 5,
    marginRight: 2
  },
  pop_setting_item_btn_text: {
    fontFamily: "pFont",
    fontSize: 12,
    height: "100%",
    color: "#fff"
  },
  pop_setting_main: {
    width: "100%",
    position: "centerTop"
  },
  pop_setting_main_1: {
    marginTop: 0,
    height: 40
  },
  pop_setting_main_2: {
    marginTop: 80,
    height: 195,
    overflow: "hidden"
  },
  pop_setting_main_3: {
    marginTop: 305,
    height: 40
  },
  pop_setting_main_4: {
    marginTop: 365,
    height: 80
  },
  pop_setting_music: {
    // position:"center",
    width: _v.screenWidth / 2 - 50
  },
  pop_setting_music_main: {
    position: "center",
    width: 80
  },
  pop_setting_effect: {
    width: _v.screenWidth / 2 - 70
    // position:"center",
  },

  pop_setting_music_title: {
    fontSize: 16,
    color: "#fff",
    fontFamily: "pFont",
    display: "line"
  },
  pop_setting_music_check: {
    width: 60,
    height: 25,
    marginTop: 10
  },
  pop_setting_music_check_bg: {
    width: 70,
    height: 25,
    borderRadius: 15
  },
  pop_setting_music_check_bg_main: {
    scale: 0.25
  },
  pop_setting_music_text: {
    position: "center",
    width: 60,
    height: 25
  },
  pop_setting_music_text_off: {
    fontSize: 14,
    color: "#fff",
    fontFamily: "pFont",
    width: 30,
    height: 25,
    textAlign: "center",
    marginLeft: 30
  },
  pop_setting_music_check_img: {
    position: "center",
    width: 50,
    height: 50,
    marginLeft: -20
  },
  pop_setting_music_check_img_main: {
    scale: 0.28,
    position: "center"
  },
  // pop_setting_music_text
  pop_setting_item: {
    height: 40,
    marginTop: 7,
    background: "#242c38",
    width: _v.screenWidth - 100,
    borderRadius: 8,
    paddingLeft: 15,
    paddingRight: 15
  },
  pop_setting_item_icon: {
    scale: 0.25,
    position: "leftCenter",
    paddingLeft: 35
  },
  pop_setting_item_text: {
    width: "100%",
    height: "100%",
    textAlign: "right",
    position: "centerRight",
    fontSize: 14,
    color: "#fff",
    paddingRight: 15,
    fontFamily: "pFont"
  },
  pop_setting_item_text_1: {
    marginLeft: 45,
    position: "centerLeft",
    textAlign: "left"
  },
  pop_setting_item_btn_disconnect: {
    width: 100,
    overflow: "hidden",
    height: 100,
    position: "centerRight",
    scale: 0.25,
    marginRight: 10
  },
  pop_setting_item_btn_disconnect_img: {
    position: "centerRight",
    width: "100%",
    height: "100%"
  },
  pop_setting_item_text_coin_sold: {
    marginRight: 15
  },
  pop_setting_item_text_coin_question: {
    position: "centerRight",
    marginRight: 18,
    marginTop: -8,
    scale: 0.22,
    width: 40,
    height: 40
  }
};