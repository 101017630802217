import "core-js/modules/es.array.push.js";
import Vue from "vue";
import { Sprite, Text, Graphics, Assets, NineSlicePlane } from "pixi.js";
import { Input } from "@pixi/ui";
import { createNamespacedHelpers } from "vuex";
const gameVuex = createNamespacedHelpers("game");
const userVuex = createNamespacedHelpers("user");
await Assets.load("up_star_bg");
await Assets.load("up_star_info_bg");
await Assets.load("up_star_title_g");
await Assets.load("up_star_title_bg");
// await _v.m_loadMinerAnimal("/assets/animation", "chaigong_1", 10, "idle");
// await _v.m_loadMinerAnimal("/assets/animation", "chaigong_2", 10, "idle");
// await _v.m_loadMinerAnimal("/assets/animation", "chaigong_3", 10, "idle");
// await _v.m_loadMinerAnimal("/assets/animation", "chaigong_4", 10, "idle");

export default {
  name: "popRecruitMiner",
  props: {
    show: Boolean
  },
  inject: ["setPageMinerDetailShow", "setPageRecruitShow", "closePage"],
  computed: {
    ...gameVuex.mapState(["selectMinerInfo"]),
    ...userVuex.mapState(["accountInfo", "gameInfo"]),
    isRender() {
      return this.$parent.popRecruitMinerShow && Boolean(this.selectMinerInfo);
    }
  },
  watch: {
    isRender: {
      handler(bool) {
        if (bool) {
          this.initPop();
        }
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      popDom: null,
      taskList: [{}, {}, {}],
      obj: {
        code: 0,
        message: "",
        data: {
          id: 79,
          type_id: 0,
          level: 1,
          star: 2,
          ratio: 1.027971347,
          user_id: 16,
          status: 0,
          position: 0,
          power: 11.15867349717027,
          next_power: 12.112788413785445,
          next_fee: 123594.74334445095
        }
      }
    };
  },
  methods: {
    ...userVuex.mapActions(["getAccountInfo"]),
    playAnim() {},
    initPop() {
      if (!this.$parent.popRecruitMinerDom) {
        this.$parent.popRecruitMinerDom = this.m_popDom(this.renderDom());
        this.gameApp.stage.addChild(this.$parent.popRecruitMinerDom);
      }
      this.$parent.popRecruitMinerDom.visible = true;
      this.$parent.popRecruitMinerDom.zIndex = this.gameApp.stage.children.length + 12;
      this.$parent.popRecruitMinerDom.position.set(0, 0);
      this.loadMinerInfo();
      this.anim_pop(this.$parent.popRecruitMinerDom);
    },
    starList(item) {
      let list = [];
      for (let i = 0; i < item.star; i++) {
        list.push({
          content: Sprite.from(this.m_getRes("element").textures["icon-star.png"]),
          styles: globalStyles.pop_upgrade_star_info_star_label
        });
      }
      return list;
    },
    loadMinerInfo() {
      const popDom = this.$parent.popRecruitMinerDom;
      const pop_upgrade_star_box_miner_main = popDom.content.getByID("pop_upgrade_star_box_miner_main");
      pop_upgrade_star_box_miner_main.children[1].textures = _v[`${this.setMiner(this.selectMinerInfo.star, "img")}-idle`];
      pop_upgrade_star_box_miner_main.children[1].play();
      const pop_upgrade_star_box_miner_name = popDom.content.getByID("pop_upgrade_star_box_miner_name");
      pop_upgrade_star_box_miner_name.children[0].text = _v[`${this.setMiner(this.selectMinerInfo.star, "name")}`];
      const pop_upgrade_star_box_miner_id = popDom.content.getByID("pop_upgrade_star_box_miner_id");
      pop_upgrade_star_box_miner_id.children[0].text = `ID:${this.selectMinerInfo.id}`;
      const pop_upgrade_star_info_star = popDom.content.getByID("pop_upgrade_star_info_star");
      pop_upgrade_star_info_star.children.map((item, index) => {
        item.visible = index <= this.selectMinerInfo.star - 1;
      });
      if (this.selectMinerInfo.star == 1) {
        pop_upgrade_star_info_star.x = _v.screenWidth * 2 - 120;
      } else if (this.selectMinerInfo.star == 2) {
        pop_upgrade_star_info_star.x = _v.screenWidth * 2 - 170;
      } else if (this.selectMinerInfo.star == 3) {
        pop_upgrade_star_info_star.x = _v.screenWidth * 2 - 220;
      } else if (this.selectMinerInfo.star == 4) {
        pop_upgrade_star_info_star.x = _v.screenWidth * 2 - 270;
      }
      const pop_upgrade_star_base_value_lv = popDom.content.getByID("pop_upgrade_star_base_value_lv");
      pop_upgrade_star_base_value_lv.children[0].text = `${this.selectMinerInfo.level}`;
      const pop_upgrade_star_base_value_power = popDom.content.getByID("pop_upgrade_star_base_value_power");
      pop_upgrade_star_base_value_power.children[0].text = `${this.toPre(this.selectMinerInfo.power * this.powerTime, 0)}/h`;
    },
    renderDom() {
      return {
        content: {
          popUpgradeStar: {
            content: {
              pop_upgrade_star_mask: {
                content: this.c_createMask()
              },
              pop_main: {
                content: {
                  pop_upgrade_star_con: {
                    content: {
                      pop_upgrade_star_box: {
                        content: {
                          pop_upgrade_star_box_bg: {
                            content: Sprite.from("up_star_bg")
                          },
                          pop_upgrade_star_box_miner: {
                            content: {
                              pop_upgrade_star_box_miner_main: {
                                content: this.m_setSpineAnmationsSprite(`${this.setMiner(1, "img")}-idle`, {
                                  loop: true,
                                  isPlay: true,
                                  animationSpeed: 0.25
                                })
                              },
                              pop_upgrade_star_box_miner_name: {
                                content: this.setMiner(1, "name")
                              },
                              pop_upgrade_star_box_miner_id: {
                                content: `ID:--`
                              }
                            }
                          },
                          pop_upgrade_star_info: {
                            content: {
                              pop_upgrade_star_info_bg: {
                                content: Sprite.from("up_star_info_bg")
                              },
                              pop_upgrade_star_info_star: {
                                content: Array.from({
                                  length: this.gameInfo.maxMinerStar
                                }, () => ({
                                  content: Sprite.from(this.m_getRes("element").textures["icon-star.png"]),
                                  styles: globalStyles.pop_upgrade_star_info_star_label
                                }))
                                // content: this.starList({ star: this.selectMinerInfo.star }),
                              }
                            }
                          },

                          pop_upgrade_star_base: {
                            content: {
                              pop_upgrade_star_base_level: {
                                content: {
                                  pop_upgrade_star_base_bg: {
                                    content: this.sf_minerDetailInfoBg({
                                      width: 465,
                                      height: 120
                                    })
                                  },
                                  pop_upgrade_star_base_label: {
                                    content: "Lv."
                                  },
                                  pop_upgrade_star_base_value_lv: {
                                    content: `--`,
                                    styles: globalStyles.pop_upgrade_star_base_value
                                  }
                                }
                              },
                              pop_upgrade_star_base_pro: {
                                content: {
                                  pop_upgrade_star_base_bg: {
                                    content: this.sf_minerDetailInfoBg({
                                      width: 465,
                                      height: 120
                                    })
                                  },
                                  pop_upgrade_star_base_label: {
                                    content: "Power"
                                  },
                                  pop_upgrade_star_base_value_power: {
                                    content: `--`,
                                    styles: globalStyles.pop_upgrade_star_base_value
                                  }
                                }
                              }
                            }
                          }
                        }
                      },
                      pop_upgrade_star_title: {
                        content: {
                          pop_upgrade_star_title_bg: {
                            content: Sprite.from("up_star_title_bg")
                          },
                          pop_upgrade_star_title_text: {
                            content: "RECRUIT"
                          }
                        }
                      },
                      pop_upgrade_star_btns: this.m_btn({
                        content: {
                          pop_upgrade_star_btns_bg: {
                            content: this.sf_btnBg({
                              width: _v.screenWidth * 4 - 600,
                              height: 170
                            }),
                            styles: globalStyles.pop_upgrade_star_btns_bg
                          },
                          pop_upgrade_star_btns_text: {
                            content: "Check",
                            styles: globalStyles.pop_upgrade_star_btns_text
                          }
                        },
                        styles: globalStyles.pop_upgrade_star_btns
                      }, async () => {
                        if (this.$parent.popRecruitInfoDom) {
                          this.$parent.popRecruitInfoDom.visible = false;
                          this.$parent.popRecruitInfoShow = false;
                        }
                        console.log("关闭");
                        this.closePop();
                      }),
                      pop_upgrade_star_btns_1: this.accountInfo.init > 1 && this.m_btn({
                        content: {
                          pop_upgrade_star_btns_bg: {
                            content: this.sf_btnSecondBg({
                              width: _v.screenWidth * 4 - 600,
                              height: 170
                            }),
                            styles: globalStyles.pop_upgrade_star_btns_bg
                          },
                          pop_upgrade_star_btns_text: {
                            content: "Continue",
                            styles: globalStyles.pop_upgrade_star_btns_text
                          }
                        },
                        styles: {
                          ...globalStyles.pop_upgrade_star_btns,
                          ...globalStyles.pop_upgrade_star_btns_1
                        }
                      }, async () => {
                        this.$parent.popRecruitMinerDom.visible = false;
                        this.$emit("update:show", false);
                      })
                    }
                  }
                }
              }
            }
          }
        },
        globalStyles
      };
    },
    onItemClick() {
      console.log("这是任务！");
    },
    closePop() {
      this.closePage();
      this.$parent.playRecuitDefaultAnim();
      this.$parent.popRecruitMinerDom.visible = false;
      this.$emit("update:show", false);
      this.setPageMinerDetailShow(true);
    }
  }
};
const globalStyles = {
  popUpgradeStar: {
    width: _v.screenWidth,
    height: _v.screenHeight,
    position: "leftTop"
  },
  pop_upgrade_star_mask: {
    position: "leftTop",
    width: _v.screenWidth,
    height: _v.screenHeight
  },
  pop_main: {
    position: "center",
    width: "100%",
    height: "100%"
  },
  pop_upgrade_star_btns: {
    position: "bottom",
    overflow: "hidden",
    height: 50,
    width: "100%"
  },
  pop_upgrade_star_btns_1: {
    position: "bottom",
    overflow: "hidden",
    height: 50,
    width: "100%",
    marginBottom: -50
  },
  pop_upgrade_star_btns_bg: {
    scale: 0.25,
    position: "center"
  },
  pop_upgrade_star_btns_text: {
    position: "center",
    ..._v.fs_fontStyle({
      fz: 18,
      st: 2,
      ds: 1
    })
  },
  pop_upgrade_star_con: {
    position: "center",
    width: "100%",
    height: 360
  },
  pop_upgrade_star_title: {
    marginTop: -40,
    position: "centerTop"
  },
  pop_upgrade_star_title_bg: {
    position: "centerTop",
    scale: 0.25
  },
  pop_upgrade_star_title_text: {
    position: "centerTop",
    marginTop: 10,
    ..._v.fs_fontStyle({
      fz: 24,
      st: 2,
      ds: 1
    })
  },
  pop_upgrade_star_close: {
    position: "centerTop",
    width: 60,
    height: 60,
    marginLeft: 170,
    marginTop: 30
  },
  pop_upgrade_star_box: {
    position: "centerTop",
    maxWidth: "100%",
    overflow: "hidden"
  },
  pop_upgrade_star_box_bg: {
    maxWidth: "105%",
    position: "center"
  },
  pop_upgrade_star_box_miner: {
    position: "centerTop",
    marginTop: 120
  },
  pop_upgrade_star_box_miner_main: {
    scale: 0.4,
    position: "centerTop",
    background: "#364254",
    padding: 150,
    borderRadius: 100
  },
  pop_upgrade_star_box_miner_name: {
    position: "centerTop",
    marginTop: 450,
    ..._v.fs_fontStyle({
      fz: 70,
      st: 6,
      ds: 4
    })
  },
  pop_upgrade_star_box_miner_id: {
    position: "centerTop",
    marginTop: 350,
    // fontFamily: "pFont",
    // fontSize:70,
    // color: "#fff",
    ..._v.fs_fontStyle({
      fz: 58,
      st: 6,
      ds: 4
    })
  },
  pop_upgrade_star_info: {
    position: "centerTop",
    marginTop: 700
  },
  pop_upgrade_star_info_bg: {},
  pop_upgrade_star_info_star: {
    position: "center"
  },
  pop_upgrade_star_info_star_label: {
    scale: 1.3,
    width: 100,
    height: 100
  },
  pop_upgrade_star_base: {
    position: "centerTop",
    width: "65%",
    marginTop: 900
  },
  pop_upgrade_star_base_level: {
    position: "left"
  },
  pop_upgrade_star_base_pro: {
    position: "right"
  },
  pop_upgrade_star_base_level: {
    position: "left"
  },
  pop_upgrade_star_base_label: {
    fontSize: 60,
    fontFamily: "pFont",
    color: "#7f83a5",
    position: "left",
    marginLeft: 30,
    marginTop: 20
  },
  pop_upgrade_star_base_value: {
    fontSize: 60,
    fontFamily: "pFont",
    color: "#fff",
    position: "right",
    marginRight: 30,
    marginTop: 20
  }
};