import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
const gameVuex = createNamespacedHelpers("game");
const userVuex = createNamespacedHelpers("user");
const walletVuex = createNamespacedHelpers("wallet");
import { Sprite, Text, Graphics, Assets, NineSlicePlane, PI_2 } from "pixi.js";
await Assets.loadBundle("invite");
await Assets.load("bg2");
import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);
export default {
  name: "invite",
  computed: {
    ...gameVuex.mapState(["gameApp", "botInfo"]),
    ...userVuex.mapState(["accountInfo", "gameInfo"]),
    ...walletVuex.mapState(["tonConnect"]),
    isRender() {
      return this.$parent.pageInviteShow;
    }
  },
  watch: {
    isRender: {
      async handler(bool) {
        if (bool) {
          await this.initScreen();
        } else {
          if (_v[`page_invite`]) {
            _v[`page_invite`].visible = false;
          }
        }
      },
      // deep: true,
      immediate: true
    }
  },
  data() {
    return {
      currentScreen: null,
      walletTimer: null,
      ref_count: new Text("0", {
        fill: "#fff",
        fontFamily: "pFont",
        fontSize: 22
      }),
      comm_coin_total: new Text("0", {
        fill: "#fff",
        fontFamily: "pFont",
        fontSize: 22
      }),
      comm_total: new Text("0", {
        fill: "#fff",
        fontFamily: "pFont",
        fontSize: 22
      }),
      refRatio: new Text("0", {
        fontSize: 14,
        color: "yellow",
        textBaseline: "bottom"
      }),
      refCoin: new Text("0", {
        fontSize: 14,
        color: "yellow",
        textBaseline: "bottom"
      }),
      refPremium: new Text("0", {
        fontSize: 14,
        color: "yellow",
        textBaseline: "bottom"
      })
    };
  },
  async beforeDestroy() {
    clearInterval(this.walletTimer);
    this.walletTimer = null;
  },
  methods: {
    ...gameVuex.mapActions(["bindWallet"]),
    ...userVuex.mapActions(["getAccountInfo"]),
    async changeAccountInfo() {
      await this.getAccountInfo();
      this.ref_count.text = this.toPre(this.accountInfo.ref_count, 0);
      this.comm_coin_total.text = this.toPre(this.accountInfo.comm_coin_total, 0);
      this.comm_total.text = this.toPre(this.accountInfo.comm_total, 2);
      this.refRatio.text = `${this.toPre(this.gameInfo.refRatio * 100, 0)}%`;
      this.refCoin.text = `${this.toPre(this.gameInfo.refCoin, 0)}`;
      this.refPremium.text = `${this.toPre(this.gameInfo.refPremium)}`;
      const incomeTotalDom = _v["page_invite"].content.getByID("invite_income_total")?.layout;
      const incomeInfoDom = _v["page_invite"].content.getByID("invite_income_info")?.layout;
      incomeTotalDom?.updateParents();
      incomeInfoDom?.updateParents();
    },
    async initScreen() {
      await this.setScreen();
    },
    async setScreen() {
      if (!_v[`page_invite`]) {
        _v[`page_invite`] = this.m_pageDom(this.renderDom());
        this.gameApp.stage.addChild(_v[`page_invite`]);
      } else {
        _v[`page_invite`].visible = true;
      }
      this.changeAccountInfo();
    },
    renderDom() {
      return {
        content: {
          invite_page: {
            content: {
              invite_bg: {
                content: Sprite.from("bg2")
              },
              invite_btn_back: {
                content: this.c_btnBack(() => {
                  _v[`page_invite`].visible = false;
                  this.$parent.pageInviteShow = false;
                })
              },
              invite_btn: this.m_btn({
                content: {
                  invite_btn_bg: {
                    content: this.sf_btnBg({
                      width: this.screenWidth * 4 - 450,
                      height: 170
                    }),
                    styles: globalStyles.invite_btn_bg
                  },
                  invite_btn_text: {
                    content: "CLICK HERE TO INVITE FRIENDS",
                    styles: globalStyles.invite_btn_text
                  }
                },
                styles: globalStyles.invite_btn
              }, async () => {
                if (!this.accountInfo.wallet) {
                  await this.m_ton(0);
                  await this.bindWallet();
                }
                this.onShare();
              }),
              invite_copy: this.m_btn({
                content: {
                  invite_btn_bg: {
                    content: this.sf_btnThreeBg({
                      width: 160,
                      height: 170
                    }),
                    styles: globalStyles.invite_btn_bg
                  },
                  invite_btn_text_copy: {
                    content: Sprite.from("icon-copy"),
                    styles: globalStyles.invite_btn_text_copy
                  }
                },
                styles: globalStyles.invite_copy
              }, async () => {
                if (!this.accountInfo.wallet) {
                  await this.m_ton(0);
                  await this.bindWallet();
                }
                // const url = `${this.botInfo.bot}?start=ref_${this.accountInfo["ref_code"]}`;
                const url = `${this.botInfo.bot}/game?startapp=ref_${this.accountInfo["ref_code"]}`;
                const that = this;
                this.$copyText(url).then(function (e) {
                  that.$toasted.clear();
                  that.$toasted.success("Copy Invite Link Success!");
                }, function (e) {});
              }),
              invite_income: {
                content: {
                  invite_income_bg: {
                    content: this.sf_inviteContentBg({
                      width: Vue.prototype.screenWidth * 4,
                      height: 240 * 4
                    })
                  },
                  invite_income_info: {
                    content: {
                      invite_income_info_item: {
                        content: {
                          invite_income_info_item_z: "1.Recharge ",
                          invite_income_info_item_x: this.refRatio,
                          invite_income_info_item_y: " Ton rebate"
                        },
                        styles: globalStyles.invite_income_info_item
                      },
                      invite_income_info_item_1: {
                        content: {
                          invite_income_info_item_z: "2.Invite a regular member and get ",
                          invite_income_info_item_x: this.refCoin,
                          invite_income_info_item_y: " coins"
                        },
                        styles: globalStyles.invite_income_info_item
                      },
                      invite_income_info_item_2: {
                        content: {
                          invite_income_info_item_z: "3.Invite a premium member and get ",
                          invite_income_info_item_x: this.refPremium,
                          invite_income_info_item_y: " coins"
                        },
                        styles: globalStyles.invite_income_info_item
                      }
                    }
                  },
                  invite_income_total: {
                    content: {
                      invite_income_total_item_invite: {
                        content: {
                          invite_income_total_item_value: {
                            content: this.ref_count
                          },
                          invite_income_total_item_label: {
                            content: "My Invite"
                          }
                        },
                        styles: globalStyles.invite_income_total_item
                      },
                      invite_income_total_item_income: {
                        content: {
                          invite_income_total_item_value: {
                            content: this.comm_coin_total
                          },
                          invite_income_total_item_label: {
                            content: "Coin Income"
                          }
                        },
                        styles: globalStyles.invite_income_total_item
                      },
                      invite_income_total_item_total: {
                        content: {
                          invite_income_total_item_value: {
                            content: this.comm_total
                          },
                          invite_income_total_item_label: {
                            content: "Ton Income"
                          }
                        },
                        styles: globalStyles.invite_income_total_item
                      }
                    }
                  },
                  invite_income_title: {
                    content: {
                      invite_income_title_bg: {
                        content: Sprite.from(_v.m_getRes("base").textures["title-bg.png"])
                      },
                      invite_income_title_text: {
                        content: "INCOME"
                      }
                    }
                  }
                }
              }
            }
          }
        },
        globalStyles
      };
    },
    onShare() {
      //const link = `https://t.me/share/url?text=${this.botInfo.invite}&url=${this.botInfo.bot}?start=ref_${this.accountInfo["ref_code"]}`;
      const link = `https://t.me/share/url?text=${this.botInfo.invite}&url=${this.botInfo.bot}/game?startapp=ref_${this.accountInfo["ref_code"]}`;
      this.WebApp.openTelegramLink(link);
    }
  }
};
const globalStyles = {
  invite_page: {
    position: "leftTop",
    width: _v.screenWidth,
    height: _v.screenHeight,
    maxWidth: _v.screenWidth,
    maxHeight: _v.screenHeight,
    fontSize: 0,
    overflow: "hiddren"
  },
  invite_bg: {
    maxWidth: "100%",
    position: "centerBottom"
  },
  invite_btn: {
    position: "centerBottom",
    marginBottom: 265,
    overflow: "hidden",
    height: 45,
    width: "100%",
    marginLeft: -20
  },
  invite_copy: {
    position: "centerBottom",
    marginBottom: 265,
    overflow: "hidden",
    height: 45,
    width: "100%",
    marginLeft: 140
  },
  invite_btn_bg: {
    scale: 0.25,
    position: "center"
  },
  invite_btn_text: {
    position: "center",
    ..._v.fs_fontStyle({
      fz: 16,
      st: 2,
      ds: 1
    })
  },
  invite_income_info: {
    position: "centerBottom",
    height: 65,
    overflow: "hidden",
    width: "100%",
    paddingTop: 5,
    paddingLeft: 80,
    paddingRight: 80,
    background: "#323d4e"
  },
  invite_income_info_item: {
    textAlign: "center",
    fontSize: 12,
    color: "#fff",
    height: 15,
    display: "line"
  },
  invite_income_info_item_x: {
    fontSize: 14,
    color: "yellow",
    textBaseline: "bottom"
  },
  invite_income_info_item_z: {
    color: "#8ca2c1"
  },
  invite_income_info_item_y: {
    color: "#8ca2c1"
  },
  invite_income: {
    position: "centerBottom",
    height: 245,
    overflow: "hidden",
    width: "100%"
  },
  invite_income_bg: {
    scale: 0.25,
    position: "centerBottom"
  },
  invite_income_title: {
    position: "centerTop",
    marginTop: -8,
    height: 80
  },
  invite_income_title_bg: {
    scale: 0.3,
    position: "centerTop"
  },
  invite_income_title_text: {
    fill: "#fff",
    fontSize: 21,
    fontFamily: "pFont",
    stroke: "#11131e",
    strokeThickness: 3,
    lineJoin: "round",
    miterLimit: 100,
    dropShadow: true,
    dropShadowAngle: 90,
    dropShadowDistance: 1,
    position: "center"
  },
  invite_income_total: {
    width: "90%",
    position: "centerTop",
    marginTop: 80
  },
  invite_income_total_item: {
    backgroundColor: "#404d60",
    borderRadius: 15,
    width: "30%",
    height: 90,
    paddingLeft: 15
  },
  invite_income_total_item_income: {
    marginLeft: 15,
    marginRight: 30
  },
  invite_income_total_item_value: {
    position: "leftCenter",
    display: "line",
    marginTop: -12,
    marginLeft: 12
  },
  invite_income_total_item_label: {
    color: "#fff",
    position: "leftCenter",
    fontSize: 12,
    marginTop: 12,
    marginLeft: 12
  },
  invite_btn_text_copy: {
    scale: 0.4,
    position: "center",
    width: 30,
    height: 30,
    marginLeft: -6,
    marginTop: -7
  }
};