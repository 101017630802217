import "core-js/modules/es.array.push.js";
import Vue from "vue";
import store from "@/store";
import { createNamespacedHelpers } from "vuex";
const gameVuex = createNamespacedHelpers("game");
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
import { Sprite, Text, Graphics, Assets, NineSlicePlane } from "pixi.js";
await Assets.load("market-title-bg");
await Assets.loadBundle("market");
const ratio = 0.82;
const scrollWidth = parseInt(_v.screenWidth * ratio);
const scrollHeight = _v.screenHeight - 120;
const scrollItemWidth = scrollWidth / 2;
const scrollItemHeight = 215;
import gsap from "gsap";
let isFlag = true;
const btnRefresh1 = Sprite.from(_v.m_getRes("element").textures["btn-refresh-1.png"]);
btnRefresh1.anchor.set(0.5);
btnRefresh1.x = btnRefresh1.x + btnRefresh1.width / 2;
btnRefresh1.y = btnRefresh1.y + btnRefresh1.height / 2;
const assetItemWidth = parseInt(_v.screenWidth * 0.82) / 3 - 4;
const [diamondProgressBar, diamondProgressText] = _v.c_columnRender({
  width: assetItemWidth,
  icon: _v.m_getRes("element").textures["icon-diamond.png"],
  progress: 0,
  offsetTextX: -14,
  button: _v.m_otherBtn({
    content: btnRefresh1
  }, async event => {
    if (isFlag) {
      _v.$toasted.clear();
      isFlag = false;
      const rotationTween = gsap.to(btnRefresh1, {
        rotation: Math.PI * 2,
        duration: 1,
        repeat: -1,
        ease: "none"
      });
      await store.dispatch("user/getAccountInfo");
      diamondProgressText.text = `${_v.toPre(store.state.user.accountInfo.diamond, 2)}`;
      let timer = setTimeout(() => {
        clearTimeout(timer);
        timer = null;
        isFlag = true;
        rotationTween.kill();
        gsap.to(btnRefresh1, {
          rotation: 0,
          duration: 0.2
        });
      }, 3000);
    } else {
      // _v.$toasted.clear();
      // _v.$toasted.error("Refreshing too quickly!");
    }
  })
});
export default {
  name: "pay_diamond",
  computed: {
    ...gameVuex.mapState(["gameApp", "diamondList"]),
    ...userVuex.mapState(["accountInfo"]),
    ...walletVuex.mapState(["tonConnect"]),
    isRender() {
      return this.$parent.pageDiamondShow;
    }
  },
  watch: {
    isRender: {
      async handler(bool) {
        if (bool) {
          await this.initScreen();
          this.assets_icon_main_diamond_text.text = this.toPre(this.accountInfo.diamond, 2);
        }
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    const diamond = diamondProgressBar;
    const diamond_text = diamondProgressText;
    return {
      currentScreen: null,
      pageDom: null,
      minerList: [],
      payTimer: null,
      assets_icon_main_diamond: diamond,
      assets_icon_main_diamond_text: diamond_text
    };
  },
  async beforeDestroy() {
    clearInterval(this.payTimer);
    this.payTimer = null;
  },
  methods: {
    ...gameVuex.mapActions(["diamondPayFunc"]),
    ...userVuex.mapActions(["getAccountInfo"]),
    initScreen() {
      this.$nextTick(async () => {
        await this.setScreen();
      });
    },
    async setScreen() {
      if (!_v[`page_payDiamond`]) {
        _v[`page_payDiamond`] = this.m_pageDom(this.renderDom());
        this.gameApp.stage.addChild(_v[`page_payDiamond`]);
      } else {
        _v[`page_payDiamond`].visible = true;
      }
      this.pageDom = _v[`page_payDiamond`];
      _v[`page_payDiamond`].zIndex = this.gameApp.stage.children.length + 15;
      this.setDom(this.pageDom);
    },
    setDom() {
      this.pageDom.sortableChildren = true;
      const payDiamond_head = this.pageDom.getChildByID("payDiamond_head");
      const payDiamond_list = this.pageDom.getChildByID("payDiamond_list");
      const payDiamondpage = this.pageDom.getChildByID("payDiamondpage");
      const payDiamond_btn_back = this.pageDom.getChildByID("payDiamond_btn_back");
      const payDiamond_coin = this.pageDom.getChildByID("payDiamond_coin");
      payDiamondpage.sortableChildren = true;
      payDiamond_head.zIndex = 2;
      payDiamond_list.zIndex = 1;
      payDiamond_btn_back.zIndex = 3;
      payDiamond_coin.zIndex = 3;
    },
    listDom() {
      const minerItemDom = (item, index) => {
        return {
          id: "payDiamond_item",
          content: {
            payDiamond_item_bg: {
              content: this.sf_taskItemBg({
                width: scrollItemWidth * 4 - 40,
                height: scrollItemHeight * 4 - 40
              }),
              styles: globalStyles.payDiamond_item_bg
            },
            payDiamond_item_goods: {
              content: {
                payDiamond_item_goods_bg: {
                  content: this.sf_goodsItemBg({
                    width: scrollItemWidth * 4 - 140,
                    height: 430
                  }),
                  styles: globalStyles.payDiamond_item_goods_bg
                },
                payDiamond_item_goods_img: {
                  content: Sprite.from(_v.m_getRes("diamond").textures[`${index >= 7 ? 7 : index + 1}.png`]),
                  styles: globalStyles.payDiamond_item_goods_img
                }
              },
              styles: globalStyles.payDiamond_item_goods
            },
            payDiamond_item_name: {
              content: `DIAMOND x${item.diamond}`,
              styles: globalStyles.payDiamond_item_name
            },
            payDiamond_item_btns: this.m_btn({
              content: {
                payDiamond_item_btns_bg: {
                  content: this.sf_btnBg({
                    width: scrollItemWidth * 4 - 120,
                    height: 150
                  }),
                  styles: globalStyles.payDiamond_item_btns_bg
                },
                payDiamond_item_btns_main: {
                  content: {
                    payDiamond_item_btns_icon: {
                      content: Sprite.from(this.m_getRes("element").textures["icon-ton.png"]),
                      styles: globalStyles.payDiamond_item_btns_icon
                    },
                    payDiamond_item_btns_text: {
                      content: `${item.ton}`,
                      styles: globalStyles.payDiamond_item_btns_text
                    }
                  },
                  styles: globalStyles.payDiamond_item_btns_main
                }
              },
              styles: globalStyles.payDiamond_item_btns
            }, () => {
              console.log(6666, this.tonConnect);
              this.$nextTick(async () => {
                // this.tonWallet.disconnect()
                await this.tonWallet.openOrCloseTonModal(true);
                clearInterval(this.payTimer);
                this.payTimer = setInterval(() => {
                  if (this.tonConnect) {
                    clearInterval(this.payTimer);
                    this.payTimer = null;
                    this.onPay(index);
                  }
                }, 1000);
              });
            })
          },
          styles: globalStyles.payDiamond_item
        };
      };
      return this.c_scrollBox(this.diamondList.map((it, idx) => {
        return this.m_btn(minerItemDom(it, idx), null, {
          width: parseInt(this.screenWidth * ratio) / 2,
          height: scrollItemHeight
        });
      }), {
        width: scrollWidth,
        height: scrollHeight,
        vertPadding: 55
      });
    },
    renderDom() {
      return {
        content: {
          payDiamondpage: {
            content: {
              payDiamondbg: {
                content: this.m_tilingSprite("bg5.jpg")
              },
              payDiamond_btn_back: {
                content: this.c_btnBack(() => {
                  _v[`page_payDiamond`].visible = false;
                  this.$parent.pageDiamondShow = false;
                })
              },
              payDiamond_coin: {
                content: {
                  content: {
                    assets_icon_main_diamond: {
                      content: this.assets_icon_main_diamond,
                      styles: globalStyles.assets_icon_main
                    }
                  },
                  styles: globalStyles.assets_item
                }
              },
              payDiamond_head: {
                content: {
                  payDiamond_head_bg: {
                    content: Sprite.from("market-title-bg")
                  },
                  payDiamondtitle: {
                    content: {
                      payDiamondtitle_bg: {
                        content: Sprite.from(this.m_getRes("base").textures["market-title.png"])
                      },
                      payDiamondtitle_text: {
                        content: "DIAMOND"
                      }
                    }
                  }
                }
              },
              payDiamond_list: {
                content: {
                  payDiamond_list_bg: {
                    content: this.sf_marketGoods({
                      width: this.screenWidth * 4 - 160,
                      height: this.screenHeight * 4 - 380
                    })
                  },
                  payDiamond_list_scroll: {
                    content: this.listDom()
                  }
                }
              }
            }
          }
        },
        globalStyles
      };
    },
    async onPay(index) {
      const result = await this.diamondPayFunc([index]);
      if (result) {
        await this.getAccountInfo();
        this.assets_icon_main_diamond_text = this.accountInfo.diamond;
      }
    },
    onMinerItem(item, index) {
      this.$router.push("/mineDetail");
      console.log(3333);
    }
  }
};
const globalStyles = {
  payDiamondpage: {
    position: "centerTop",
    width: "100%",
    height: "100%",
    maxWidth: "100%",
    maxHeight: "100%",
    fontSize: 0,
    overflow: "hiddren"
  },
  payDiamondbg: {
    maxWidth: "100%",
    position: "centerBottom"
  },
  payDiamond_coin: {
    position: "topRight",
    marginTop: 15
  },
  payDiamondcoin: {
    position: "topRight",
    marginTop: 15
  },
  payDiamond_head: {
    position: "centerTop",
    height: 520,
    overflow: "hidden",
    marginTop: -10
  },
  payDiamond_head_bg: {
    maxWidth: _v.screenWidth,
    position: "centerTop"
  },
  payDiamondtitle: {
    position: "centerTop",
    marginTop: 60,
    width: "100%",
    height: 40
  },
  payDiamondtitle_bg: {
    position: "centerTop",
    scale: 0.25
  },
  payDiamondtitle_text: {
    marginLeft: 5,
    position: "center",
    ..._v.fs_fontStyle({
      fz: 19,
      st: 2,
      ds: 1
    })
  },
  payDiamond_list: {
    position: "centerTop",
    marginTop: 70
  },
  payDiamond_list_bg: {
    position: "centerTop",
    scale: 0.25
  },
  payDiamond_list_scroll: {
    position: "centerTop"
    // marginLeft: 35,
  },

  payDiamond_item: {
    // anchorX: 0.5,
    // minWidth: "100%",
  },
  payDiamond_item_bg: {
    position: "centerTop",
    maxWidth: "100%",
    scale: 0.25
  },
  payDiamond_item_goods: {
    position: "centerTop",
    marginTop: 15,
    scale: 0.25
  },
  payDiamond_item_goods_bg: {
    position: "centerTop"
  },
  payDiamond_item_goods_img: {
    position: "center"
  },
  payDiamond_item_name: {
    position: "centerTop",
    marginTop: 130,
    fontFamily: "pFont",
    fontSize: 16,
    fill: "#2e3443"
  },
  payDiamond_item_btns: {
    marginTop: 155,
    width: "100%",
    height: 40
  },
  payDiamond_item_btns_bg: {
    position: "center",
    scale: 0.25
  },
  payDiamond_item_btns_main: {
    position: "center"
  },
  payDiamond_item_btns_icon: {
    marginTop: 4,
    width: 28,
    height: 28,
    scale: 0.25,
    marginRight: -3
  },
  payDiamond_item_btns_text: {
    fill: "#fff",
    fontSize: 18,
    fontFamily: "pFont",
    stroke: "#11131e",
    strokeThickness: 2,
    lineJoin: "round",
    miterLimit: 100,
    dropShadow: true,
    dropShadowAngle: 90,
    dropShadowDistance: 1
  }
};