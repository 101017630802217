import { Assets, Graphics, Text, Sprite, Texture, TextStyle, Container } from "pixi.js";
import { ScrollBox, Input, ProgressBar } from "@pixi/ui";
import { Layout, LayoutSystem } from "@pixi/layout";
import TextInput from "@game/components/TextInput";
import gsap from "gsap";
import router from "@/router";

function buildContent(parent, content) {
  for (const key in content) {
    const element = content[key];
    let displayObject;

    displayObject = new Container();
    buildContent(displayObject, element.content);

    console.log("scrollBox parent", parent);
    parent.addChild(displayObject);
  }
}

function getItemContent(index, _itemDom) {
  const container = new Container();
  buildContent(container, _itemDom);
  return container;
}

// function createVirtualList(scrollbox, options) {
//   const { x, y, boxWidth, boxHeight, totalItems, itemWidth, itemHeight, itemDom } = options;
//   console.log("scrollbox Dom", scrollbox, options);
//   scrollbox.x = x;
//   scrollbox.y = y;

//   const visibleItemsCount = Math.ceil(boxHeight / itemHeight) + 1;
//   console.log("visibleItemsCount", visibleItemsCount);
//   let items = [];

//   const contentHeight = totalItems * itemHeight;
//   scrollbox.contentHeight = contentHeight;
//   console.log("scrollbox.contentHeight", scrollbox, scrollbox.contentHeight);
//   const numArr = Array.from({ length: visibleItemsCount }, (_, index) => index);
//   console.log("numArr========", numArr, itemDom);
//   const _list = numArr.map((it) => {
//     return _v.m_btn(itemDom, null, {
//       width: itemWidth,
//       height: itemHeight,
//     });
//   });
//   // for (let i = 0; i <= visibleItemsCount; i++) {
//   //   console.log("itemWidth", itemWidth, itemHeight);
//   //   let layout = _v.m_btn(itemDom, null,{
//   //     width: itemWidth,
//   //     height: itemHeight
//   //   })
//   //   console.log("layoutlayoutlayout", layout);
//   //   // items.push(layout);
//   //   scrollbox.addItem(layout)
//   // }

//   console.log("itemsitems", itemDom);

//   scrollbox.addItems(_list)

//   console.log("scrollbox rrr", scrollbox);

//   // let firstVisibleItemIndex = 0;

//   // scrollbox.on("pointertap", () => {
//   //   console.log("scrollTop", scrollbox);
//   //   // const newFirstVisibleItemIndex = Math.floor(scrollTop / itemHeight);
//   //   // console.log("newFirstVisibleItemIndex", newFirstVisibleItemIndex, firstVisibleItemIndex);

//   //   // if (newFirstVisibleItemIndex !== firstVisibleItemIndex) {
//   //   //   firstVisibleItemIndex = newFirstVisibleItemIndex;

//   //   //   items.forEach((item, index) => {
//   //   //     const itemIndex = firstVisibleItemIndex + index;
//   //   //     const newItemContent = getItemContent(itemIndex);
//   //   //     item.removeChildren();
//   //   //     newItemContent.children.forEach((child) => item.addChild(child));
//   //   //     item.y = itemIndex * itemHeight;
//   //   //   });
//   //   // }
//   // });

//   // scrollbox.update();
//   return scrollbox;
// }

export default {
  c_columnRender(option = {}) {
    let canvas = document.createElement("canvas");
    canvas.width = option?.width ? option.width : 350;
    canvas.height = option?.height ? option.height : 25;

    let ctx = canvas.getContext("2d");
    // 创建渐变对象
    let gradient = ctx.createLinearGradient(0, 0, option?.width ? option.width : 350, 0);
    gradient.addColorStop(0, "#faa10a");
    gradient.addColorStop(1, "#face00");

    // 填充渐变到画布
    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, (option.width / 100) * option.progress, option?.height ? option.height : 25);

    // 创建纹理
    let texture = Texture.from(canvas);
    const args = {
      fillColor: option?.width ? option.width : "#face00",
      borderColor: "#000000",
      backgroundColor: "#0a1e33",
      width: option?.width ? option.width : 200,
      height: option?.height ? option.height : 25,
      radio: option?.radio ? option.radio : 6,
      border: option?.border ? option.border : 1.5,
      vertical: option?.vertical ? option.vertical : false,
      animate: true,
      progress: option?.progress ? option.progress : 0,
      offsetTextX: option?.offsetTextX ? option.offsetTextX : 0,
      button: option?.button ? option?.button : null,
    };

    const bg = new Graphics()
      .beginFill(args.borderColor)
      .drawRoundedRect(0, 0, args.width, args.height, args.radio)
      .beginFill(args.backgroundColor)
      .drawRoundedRect(args.border, args.border, args.width - args.border * 2, args.height - args.border * 2, args.radio);
    bg.alpha = 0.7;

    const fill = new Graphics()
      .beginFill(args.borderColor)
      .drawRoundedRect(0, 0, args.width, args.height, args.radio)
      .beginTextureFill({ texture })
      .drawRoundedRect(args.border, args.border, args.width - args.border * 2, args.height - args.border * 2, args.radio);

    const progressBar = new ProgressBar({
      bg,
      fill,
      progress: args.progress,
    });

    const progressText = new Text("0", {
      fontSize: option?.fontSize ? option.fontSize : 13,
      fontFamily: "pFont",
      fill: "#fff",
    });
    progressText.anchor.set(1, 0.5);
    progressText.x = progressBar.width - 5 + args.offsetTextX;
    progressText.y = progressBar.height / 2;

    const columnIcon = option?.icon ? Sprite.from(option.icon) : null;
    if (columnIcon) {
      columnIcon.scale.set(option?.iconScale ? option?.iconScale : 0.3);
      columnIcon.position.x = option?.iconOffsetX ? option.iconOffsetX : -14;
      columnIcon.position.y = option?.iconOffsetY ? option.iconOffsetY : -4;
      columnIcon.zIndex = 3;
      // _v.m_setAlign(graphics, columnIcon, "y");
      progressBar.addChild(columnIcon);
    }

    progressBar.addChild(progressText);
    if (args.button) {
      args.button.scale.set(0.25);
      args.button.position.x = progressBar.width - 30;
      progressBar.addChild(args.button);
    }

    return [progressBar, progressText];
  },
  c_progress(option) {
    let canvas = document.createElement("canvas");
    canvas.width = option?.width ? option.width : 350;
    canvas.height = option?.height ? option.height : 25;

    let ctx = canvas.getContext("2d");
    // 创建渐变对象
    let gradient = ctx.createLinearGradient(0, 0, option?.width ? option.width : 350, 0);
    gradient.addColorStop(0, "#faa10a");
    gradient.addColorStop(1, "#face00");

    // 填充渐变到画布
    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, (option.width / 100) * option.progress, option?.height ? option.height : 25);

    // 创建纹理
    let texture = Texture.from(canvas);
    const args = {
      fillColor: option?.width ? option.width : "#face00",
      borderColor: "#000000",
      backgroundColor: "#0a1e33",
      width: option?.width ? option.width : 200,
      height: option?.height ? option.height : 15,
      radio: option?.radio ? option.radio : 25,
      border: option?.border ? option.border : 1,
      vertical: option?.vertical ? option.vertical : false,
      animate: true,
      progress: option?.progress ? option.progress : 0,
    };

    const bg = new Graphics()
      .beginFill(args.borderColor)
      .drawRoundedRect(0, 0, args.width, args.height, args.radio)
      .beginFill(args.backgroundColor)
      .drawRoundedRect(args.border, args.border, args.width - args.border * 2, args.height - args.border * 2, args.radio);
    bg.alpha = 0.7;

    const fill = new Graphics()
      .beginFill(args.borderColor)
      .drawRoundedRect(0, 0, args.width, args.height, args.radio)
      .beginTextureFill({ texture })
      .drawRoundedRect(args.border, args.border, args.width - args.border * 2, args.height - args.border * 2, args.radio);

    const progressBar = new ProgressBar({
      bg,
      fill,
      progress: args.progress,
    });

    const progressText = new Text("", {
      fontSize: option?.fontSize ? option.fontSize : 13,
      fontFamily: "pFontM",
      fill: "#fff",
    });
    progressText.anchor.set(1, 0.5);
    progressText.x = progressBar.width - 5;
    progressText.y = progressBar.height / 2;

    progressBar.addChild(progressText);

    return { progressBar, progressText };
  },
  c_btnBack: (callback) => {
    return _v.m_btn(
      {
        content: Sprite.from(_v.m_getRes("element").textures["btn-back.png"]),
        styles: {
          position: "topLeft",
          maxWidth: 40,
          marginTop: 10,
          marginLeft: 15,
        },
      },
      () => {
        callback();
      }
    );
  },
  c_scrollBox(content, option) {
    const scrollBox = new ScrollBox({
      radius: option?.radius ? option.radius : 0,
      width: option?.width ? option.width : 0,
      height: option?.height ? option.height : 0,
      type: option?.type ? option.type : "", // '' | 'vertical'
      items: content,
      padding: option?.padding ? option.padding : 0,
      horPadding: option?.horPadding ? option.horPadding : 0,
      vertPadding: option?.vertPadding ? option.vertPadding : 0,
      elementsMargin: option?.margin ? option.margin : 0,
      shiftScroll: option?.shiftScroll ? option.shiftScroll : false,
      // disableDynamicRendering: true
    });

    return scrollBox;
  },
  c_rankingScrollBox(content, option) {
    console.log("scrollBox option:", option);
    const scrollBox = new ScrollBox({
      radius: option?.radius ? option.radius : 0,
      width: option?.width ? option.width : 0,
      height: option?.height ? option.height : 0,
      type: option?.type ? option.type : "", // '' | 'vertical'
      // items: content,
      padding: option?.padding ? option.padding : 0,
      horPadding: option?.horPadding ? option.horPadding : 0,
      vertPadding: option?.vertPadding ? option.vertPadding : 0,
      elementsMargin: option?.margin ? option.margin : 0,
    });
    return createVirtualList(scrollBox, {
      x: scrollBox.x,
      y: scrollBox.y,
      boxWidth: scrollBox.width,
      boxHeight: scrollBox.height,
      totalItems: content.length,
      ...option,
    });
  },
  c_mask: (dom, option) => {
    const width = dom.width;
    const height = dom.height;
    const mask = new Graphics();
    mask.beginFill(0x000000); // 白色填充
    mask.drawRect(0, 0, width, height); // 画一个矩形
    mask.endFill();
    mask.alpha = option?.alpha ? option.alpha : 0.8; // 设置遮罩的透明度为 50%

    return mask;
  },
  c_btnClose: (callback, option) => {
    return _v.m_btn(
      {
        content: Sprite.from(_v.m_getRes("element").textures["btn-close.png"]),
        styles: {
          position: option?.position ? option.position : "rightTop",
          maxWidth: option?.maxWidth ? option?.maxWidth : 30,
          marginTop: option?.marginTop ? option?.marginTop : -10,
          marginRight: option?.marginRight ? option.marginRight : 20,
        },
      },
      () => {
        typeof callback == "function" && callback();
      }
    );
  },
  c_main_tab: (list, isTab, callback, option = {}) => {
    const width = option?.width ? option.width : 140;

    const tabItemNormal = (idx, arr) => Sprite.from(_v.m_getRes("base").textures[`tab-main-${idx == 0 ? "left" : "right"}-normal.png`]);
    const tabItemSelect = (idx, arr, type) => _v.m_getRes("base").textures[`tab-main-${idx == 0 ? "left" : "right"}-${type}.png`];

    const tabListDOM = _v.m_popDom({
      content: list.map((item, index) => {
        let cur = {};
        cur[`tab_item_${index}`] = _v.m_btn(
          {
            content: {
              tab_item_bg: {
                content: tabItemNormal(index, list),
                styles: {
                  width,
                  scale: 0.26,
                  height: 40,
                },
              },
              tab_item_text: {
                content: item.label,
                styles: {
                  marginTop: -1,
                  // anchorX: 0.5,
                  marginLeft: index <= 0 ? 15 : -15,
                  position: "center",
                  fill: "#fff",
                  fontSize: 14,
                  fontFamily: "pFont",
                  stroke: "#11131e",
                  strokeThickness: 2,
                  lineJoin: "round",
                  miterLimit: 100,
                  dropShadow: true,
                  dropShadowAngle: 90,
                  dropShadowDistance: 1,
                },
              },
            },
            styles: { item },
          },
          () => {
            isTab = item.value;
            let num = 0;
            tabListDOM?.content?.children.forEach((itm, key) => {
              console.log("itm, key", itm, key);
              const otherItemBg = itm?.content?.getByID("tab_item_bg").children[0];
              console.log("otherItemBg", otherItemBg);
              otherItemBg.texture = tabItemSelect(num, list, "normal");
              console.log("otherItemBg.texture", otherItemBg.texture);
              num++;
            });
            const selectTab = cur[`tab_item_${isTab}`]?.content?.getByID("tab_item_bg")?.children[0];
            if (!selectTab) return;
            selectTab.texture = tabItemSelect(isTab, list, "select");
            callback(item);
          },
          {
            gasp: true,
            result: 0.9,
            init: 1,
          }
        );
        return cur;
      }),
      styles: {
        position: "center",
      },
    });
    let num = 0;
    tabListDOM?.content?.children.forEach((itm, key) => {
      if (num == isTab) {
        const otherItemBg = itm?.content?.getByID("tab_item_bg").children[0];
        otherItemBg.texture = tabItemSelect(isTab, list, "select");
      }
      num++;
    });

    return tabListDOM;
  },
  c_tab: (list, isTab, callback, option = {}) => {
    const ratio = option?.ratio ? option.radtio : 3.35;
    const width = option?.width ? option.width : 100;

    const _style = { width: width * ratio, height: 120, leftWidth: 40, topHeight: 40, rightWidth: 40, bottomHeight: 40 };
    const tabItemNormal = (idx, arr) => _v.m_spriteFrame(_v.m_getRes("base").textures[`tab-${idx == 0 ? "left" : idx == arr.length - 1 ? "right" : "center"}-normal-bg.png`], _style);
    const tabItemSelect = (idx, arr, type) => _v.m_getRes("base").textures[`tab-${idx == 0 ? "left" : idx == arr.length - 1 ? "right" : "center"}-${type}-bg.png`];
    console.log("tabItemSelect", tabItemSelect);

    const tabListDOM = _v.m_popDom({
      content: list.map((item, index) => {
        let cur = {};
        cur[`tab_item_${index}`] = _v.m_btn(
          {
            content: {
              tab_item_bg: {
                content: tabItemNormal(index, list),
                styles: {
                  width,
                  scale: 0.3,
                  height: 40,
                },
              },
              tab_item_text: {
                content: item.label,
                styles: {
                  marginTop: -3,
                  marginLeft: 6,
                  anchorX: 0.5,
                  position: "center",
                  fill: "#fff",
                  fontSize: 14,
                  fontFamily: "pFont",
                  stroke: "#11131e",
                  strokeThickness: 2,
                  lineJoin: "round",
                  miterLimit: 100,
                  dropShadow: true,
                  dropShadowAngle: 90,
                  dropShadowDistance: 1,
                },
              },
            },
            styles: { item },
          },
          () => {
            isTab = item.value;
            let num = 0;
            tabListDOM?.content?.children.forEach((itm, key) => {
              const otherItemBg = itm?.content?.getByID("tab_item_bg").children[0];
              otherItemBg.texture = tabItemSelect(num, list, "normal");
              num++;
            });
            const selectTab = cur[`tab_item_${isTab}`]?.content?.getByID("tab_item_bg")?.children[0];
            if (!selectTab) return;
            selectTab.texture = tabItemSelect(isTab, list, "select");
            callback(item);
          },
          {
            gasp: true,
            result: 0.9,
            init: 1,
          }
        );
        return cur;
      }),
      styles: {
        position: "center",
      },
    });
    let num = 0;
    tabListDOM?.content?.children.forEach((itm, key) => {
      if (num == isTab) {
        const otherItemBg = itm?.content?.getByID("tab_item_bg").children[0];
        otherItemBg.texture = tabItemSelect(isTab, list, "select");
      }
      num++;
    });

    return tabListDOM;
  },
  c_btnLoading: (_color = 0xffffff) => {
    let loaderContainer = new Container();
    // 设置加载动画的参数
    const loaderRadius = 10;
    const dotRadius = 3;
    const dotCount = 7;
    const color = _color;
    console.log("loaderContainer", loaderContainer);
    // 创建加载动画的点
    for (let i = 0; i < dotCount; i++) {
      let angle = (i / dotCount) * (2 * Math.PI);
      let dot = new Graphics();
      dot.beginFill(color);
      dot.drawCircle(0, 0, dotRadius);
      dot.endFill();
      dot.x = loaderRadius * Math.cos(angle);
      dot.y = loaderRadius * Math.sin(angle);
      loaderContainer.addChild(dot);
    }
    gsap.to(loaderContainer, {
      duration: 2, // Duration of one full rotation
      rotation: Math.PI * 2, // Rotate 360 degrees (2 * Math.PI radians)
      repeat: -1, // Infinite repeats
      ease: "linear", // Linear easing for smooth continuous rotation
    });
    // _v.m_updateDom(() => {
    //   loaderContainer.rotation += 0.05 * speed;
    // }, "btnLoadingAnim");
    return loaderContainer;
  },
  c_listLoading: (loc = 200) => {
    const dom = new Layout({
      content: _v.c_btnLoading(),
      styles: {
        position: "centerTop",
        marginTop: loc,
        width: 50,
        height: 50,
        anchorX: 0,
      },
    });
    return {
      render() {
        return dom;
      },
      show() {
        console.log("dom show", dom);
        dom.visible = true;
      },
      hide() {
        dom.visible = false;
        console.log("dom hide", dom);
      },
    };
  },

  c_page: (total, callback, option) => {
    if (total <= 0 || total <= option.perPage) {
      return " ";
    }
    console.log("option======", option);
    let page = option?.page ? option.page : 1;
    let perPage = option?.perPage ? (option.perPage < total ? option.perPage : total) : 5;
    const layout = new Container();
    const totalItems = total;
    const totalPages = perPage > 0 ? Math.ceil(totalItems / perPage) : 0;

    let buttonStyle = { fontSize: 16, fontFamily: "pFont", align: "center", fill: 0xffffff };

    // 显示当前页项目的函数
    function displayPage(page, status) {
      layout.removeChildren();
      displayPaginationControls(page, status);
    }

    // 创建按钮函数
    function createButton(text, style, onClick, _page, pageNumber) {
      let button = null;
      if (typeof text == "string") {
        button = new Text(text, style);
      } else {
        button = text;
      }


      let buttonBg = null;
      buttonBg = Sprite.from(_v.m_getRes("base").textures["page_normal.png"]);
      if (_page === pageNumber) {
        buttonBg = Sprite.from(_v.m_getRes("base").textures["page_select.png"]);
      }
      buttonBg.scale.set(0.22);
      buttonBg.scale.set(0.22);

      let container = new Container();
      container.addChild(buttonBg);
      container.addChild(button);
      container.interactive = true;
      container.buttonMode = true;
      container.on("pointerdown", onClick);
      _v.m_setAlign(buttonBg, button, "xy");

      return container;
    }

    // 显示分页控件的函数
    function displayPaginationControls(page, status) {
      layout.removeChildren();
      const paginationContainer = new Container();
      paginationContainer.y = 0;
      layout.addChild(paginationContainer);

      const addPaginationButton = (text, pageNumber) => {
        const button = createButton(
          text,
          buttonStyle,
          () => {
            if (pageNumber !== null && pageNumber !== page) {
              page = pageNumber;
              displayPage(page);
              typeof callback == "function" && callback(page);
            }
          },
          page,
          pageNumber
        );
        paginationContainer.addChild(button);
      };
      if(page>1) {
        const leftArrow = Sprite.from(_v.m_getRes("element").textures["icon-page-arrow.png"]);
        leftArrow.anchor.set(0.3, 0.4);
        leftArrow.scale.set(-0.22, 0.22);
        addPaginationButton(leftArrow, page - 1);
      }

      // 始终显示第一页
      addPaginationButton("1", 1);

      let startPage = Math.max(2, page - 1);
      console.log("totalPages - 1", totalPages - 1, page + 1);
      let endPage = Math.min(totalPages - 1, page + 1);

      if (startPage > 2) {
        addPaginationButton("...", Math.floor((startPage - 1) / 2));
      }

      if (endPage < totalPages - 1) {
        endPage = Math.max(endPage, 3);
      }

      for (let i = startPage; i <= endPage; i++) {
        console.log("iiiii", i, startPage, endPage);
        addPaginationButton(i.toString(), i);
      }
      if (endPage < totalPages - 1) {
        addPaginationButton("...", Math.floor((endPage + totalPages) / 2));
      }
      // 始终显示最后一页
      addPaginationButton(totalPages.toString(), totalPages);
      // 添加下一页按钮
      if(startPage<endPage){
        const rightArrow = Sprite.from(_v.m_getRes("element").textures["icon-page-arrow.png"]);
        rightArrow.anchor.set(0.3, 0.4);
        rightArrow.scale.set(0.25, 0.25);
        addPaginationButton(rightArrow, page + 1);
      }

      // 更新位置
      let offsetX = 0;
      paginationContainer.children.forEach((child) => {
        child.x = offsetX;
        offsetX += child.width + 5;
      });
    }

    // 初始化显示第一页
    displayPage(page, "init");
    layout.visible = true;
    return layout;
  },
  c_marketPage: () => {
    const layout = new Container();
    let currentCallback = null;

    let buttonStyle = { fontSize: 16, fontFamily: "pFont", align: "center", fill: 0xffffff };

    // 创建按钮函数
    function createButton(text, style, onClick, _page, pageNumber) {
      let button = null;
      if (typeof text == "string") {
        button = new Text(text, style);
      } else {
        button = text;
      }
      let buttonBg = null;
      buttonBg = Sprite.from(_v.m_getRes("base").textures["page_normal.png"]);
      if (_page === pageNumber) {
        buttonBg = Sprite.from(_v.m_getRes("base").textures["page_select.png"]);
      }
      
      buttonBg.scale.set(0.22);
      buttonBg.scale.set(0.22);
      
      let container = new Container();
      container.addChild(buttonBg);
      container.addChild(button);
      container.interactive = true;
      container.buttonMode = true;
      container.on("pointerdown", onClick);
      _v.m_setAlign(buttonBg, button, "xy");

      return container;
    }

    // 显示分页控件的函数
    function displayPaginationControls(page, total, perPage, callback) {
      currentCallback = callback; // 将当前回调函数存储在外部变量中
      console.log("callbackcallbackcallback", currentCallback);
      const totalPages = perPage > 0 ? Math.ceil(total / perPage) : 0;
      console.log("totalPagestotalPages", totalPages);
      layout.removeChildren();
      const paginationContainer = new Container();
      paginationContainer.y = 0;
      layout.addChild(paginationContainer);

      const addPaginationButton = (text, pageNumber) => {
        const button = createButton(
          text,
          buttonStyle,
          () => {
            if (pageNumber !== null && pageNumber !== page) {
              page = pageNumber;
              displayPaginationControls(page, total, perPage, currentCallback);
              if (typeof currentCallback === "function") {
                currentCallback(page);
              }
            }
          },
          page,
          pageNumber
        );
        paginationContainer.addChild(button);
      };
      
      let startPage = Math.max(2, page - 1);
      
      let endPage = Math.min(totalPages - 1, page + 1);
      if(page>1) {
        const leftArrow = Sprite.from(_v.m_getRes("element").textures["icon-page-arrow.png"]);
        leftArrow.anchor.set(0.3, 0.4);
        leftArrow.scale.set(-0.22, 0.22);
        addPaginationButton(leftArrow, page - 1);
      }

      // 始终显示第一页
      addPaginationButton("1", 1);


      if (startPage > 2) {
        addPaginationButton("...", Math.floor((startPage - 1) / 2));
      }

      if (endPage < totalPages - 1) {
        endPage = Math.max(endPage, 3);
      }

      for (let i = startPage; i <= endPage; i++) {
        console.log("iiiii", i, startPage, endPage);
        addPaginationButton(i.toString(), i);
      }
      if (endPage < totalPages - 1) {
        addPaginationButton("...", Math.floor((endPage + totalPages) / 2));
      }
      // 始终显示最后一页
      addPaginationButton(totalPages.toString(), totalPages);
      // 添加下一页按钮

      if(startPage<endPage) {
        const rightArrow = Sprite.from(_v.m_getRes("element").textures["icon-page-arrow.png"]);
        rightArrow.anchor.set(0.3, 0.4);
        rightArrow.scale.set(0.25, 0.25);
        addPaginationButton(rightArrow, page + 1);
      }

      // 更新位置
      let offsetX = 0;
      paginationContainer.children.forEach((child) => {
        child.x = offsetX;
        offsetX += child.width + 5;
      });
    }

    return {
      render: (page, total, perPage) => {
        displayPaginationControls(page, total, perPage);
        return layout;
      },
      show: (page, total, perPage, callback) => {
        displayPaginationControls(page, total, perPage, callback);
      },
    };
  },
  c_createMask: (alpha = 0.8) => {
    const width = _v.screenWidth;
    const height = _v.screenHeight;
    const mask = new Graphics();
    mask.beginFill(0x000000);
    mask.drawRect(0, 0, width, height);
    mask.endFill();
    mask.alpha = alpha;
    console.log("mask", mask);
    return mask;
  },
  c_empty: (content, option, callback) => {
    const isbtn = option.isBtn;
    const btnText = option?.btnText ? option.btnText : _v.$lang("Home");
    return {
      content: {
        miner_empty_main: {
          content: {
            miner_empty_text: {
              content,
              styles: {
                height: 25,
                fontSize: 16,
                width: "100%",
                textAlign: "center",
                ..._v.fs_fontStyle({ fz: 20, st: 1 }),
              },
            },
            miner_empty_btn: isbtn && {
              content: {
                miner_empty_btn_bg: {
                  content: _v.sf_btnBg({
                    width: 150 * 4,
                    height: 170,
                  }),
                  styles: {
                    scale: 0.25,
                    position: "center",
                  },
                },
                miner_empty_btn_text: _v.m_btn(
                  {
                    content: btnText,
                    styles: {
                      ..._v.fs_fontStyle({ fz: 16 }),
                      width: "100%",
                      textAlign: "center",
                      position: "center",
                    },
                  },
                  () => {
                    typeof callback == "function" && callback();
                  }
                ),
              },
              styles: {
                height: 40,
                width: "100%",
                overflow: "hidden",
              },
            },
          },
          styles: {
            width: _v.screenWidth,
            height: "100%",
            position: "centerTop",
          },
        },
      },
      styles: {
        marginTop: 100,
        position: "centerTop",
        width: "100%",
        height: 400,
      },
    };
  },
  c_comingsoon: (content, option, callback) => {
    const isbtn = option.isBtn;
    const btnText = option?.btnText ? option.btnText : _v.$lang("Home");
    const height = 110;
    const icon = Sprite.from(_v.m_getRes("element").textures["icon-lock.png"]);
    icon.x = _v.screenWidth / 2 - icon.width / 2;
    return {
      content: {
        miner_empty_main: {
          content: {
            miner_empty_icon: {
              content: icon,
              styles: {
                position: "centerTop",
                scale: 0.6,
                width: 120,
                height: 120,
              },
            },
            miner_empty_text: {
              content,
              styles: {
                position: "centerBottom",
                height: 25,
                width: "100%",
                textAlign: "center",
                ..._v.fs_fontStyle({ fz: 20, st: 1 }),
              },
            },
            miner_empty_btn: isbtn && {
              content: {
                miner_empty_btn_bg: {
                  content: _v.sf_btnBg({
                    width: 150 * 4,
                    height: 170,
                  }),
                  styles: {
                    scale: 0.25,
                    position: "center",
                  },
                },
                miner_empty_btn_text: _v.m_btn(
                  {
                    content: btnText,
                    styles: {
                      ..._v.fs_fontStyle({ fz: 16 }),
                      width: "100%",
                      textAlign: "center",
                      position: "center",
                    },
                  },
                  () => {
                    typeof callback == "function" && callback();
                  }
                ),
              },
              styles: {
                height: 40,
                width: "100%",
                overflow: "hidden",
              },
            },
          },
          styles: {
            width: _v.screenWidth,
            height,
            position: "centerTop",
          },
        },
      },
      styles: {
        marginTop: 100,
        position: "centerTop",
        width: "100%",
        height,
      },
    };
  },

  c_inputx: (option = {}) => {
    function generateBox(w, h) {
      var box = new Container();
      var sprite = new Graphics().beginFill("#28313f", 1).drawRoundedRect(0, 0, width, height, 8).endFill();
      var mask = new Graphics();

      mask.beginFill(0);
      mask.drawRoundedRect(0, 0, w, h, 8);

      box.addChild(sprite);
      box.addChild(mask);
      sprite.mask = mask;

      return box;
    }
    let width = option?.width ? option.width : _v.screenWidth - 135;
    let height = option?.height ? option.height : 35;
    const input = new TextInput({
      input: {
        fontFamily: "pFont",
        fontSize: "16px",
        padding: "5px 10px 10px 40px",
        width: `${width}px`,
        color: "white",
      },
      box: generateBox,
    });
    input.placeholder = "";
    input.x = width;
    input.y = 0;
    // input.focus()
    if (option?.change) {
      input.on("input", () => {
        try {
          typeof option.change == "function" && option.change(input);
        } catch (err) {
          console.log(err);
        }
      });
    }
    if (option?.enter) {
      input.on("blur", (e) => {
        try {
          typeof option.change == "function" && option.change(input);
        } catch (err) {
          console.log(err);
        }
      });
    }

    return input;
  },
  c_guide: (dom, option) => {
    if (option?.hint) {
      // 创建一个容器来放置浮窗
      const tooltipContainer = new Container();

      // 创建浮窗背景
      const tooltipBackground = new Graphics();
      tooltipContainer.addChild(tooltipBackground);

      // 创建文本容器
      const textContainer = new Container();
      tooltipContainer.addChild(textContainer);

      // 设置段落文本的样式
      const textStyle = {
        fontFamily: "pFont",
        fontSize: 14,
        fill: 0x000000, // 黑色
        wordWrap: true,
        wordWrapWidth: _v.screenWidth / 2, // 设定折行的最大宽度
      };
      // 设置文本居中并调整背景尺寸
      function updateTooltip() {
        const padding = 20;
        const containerWidth = textContainer.width;
        const containerHeight = textContainer.height;

        tooltipBackground.clear();
        tooltipBackground.beginFill(0xffffff); // 白色
        tooltipBackground.drawRoundedRect(0, 0, containerWidth + padding * 2, containerHeight + padding * 2, 25);
        tooltipBackground.endFill();

        textContainer.x = padding;
        textContainer.y = padding;
      }

      // 初次设置
      updateTooltip();

      // 动态更新文本内容时重新调整背景尺寸
      function setTooltipText(newText) {
        textContainer.removeChildren();
        const padding = 3; // 每行间距
        // const splitText  =' '
        newText = newText.replace("!", "!\n");
        newText = newText.replace(".", ".\n");
        const splitText = newText.split(/[\n]/); // 使用正则表达式按句号和感叹号分割

        let totalHeight = 0;
        splitText.forEach((text) => {
          const textElement = new Text(text, textStyle);
          textElement.y = totalHeight;
          textContainer.addChild(textElement);
          totalHeight += textElement.height + padding; // 更新总高度
        });

        updateTooltip();
      }
      setTooltipText(option.hint);
      let offsetY = option?.offsetY ? option.offsetY : 0;
      tooltipContainer.x = _v.screenWidth / 2 - tooltipContainer.width / 2;
      tooltipContainer.y = _v.screenHeight / 2 - tooltipContainer.height / 2 + offsetY;
      dom.addChild(tooltipContainer);
    }
    const mask = new Graphics();
    mask.clear();
    mask.beginFill(0x000000, 0.9); // 黑色半透明
    // 绘制全屏矩形
    mask.drawRect(0, 0, _v.screenWidth, _v.screenHeight);

    if (option?.callback) {
      dom.interactive = true;
      dom.buttonMode = true;
      dom.onpointerup = () => {
        option.callback();
      };
    }

    let type = option?.type ? option.type : "circle";
    const guideHand = Sprite.from("guide-hand");
    const guideHandW = 50;
    guideHand.width = guideHandW;
    guideHand.height = guideHandW;
    if (type == "other") {
    } else if (type === "circle") {
      guideHand.x = option?.guideHandX ? option.guideHandX + option.x + 65 : option.x + 65;
      guideHand.y = option?.guideHandY ? option.guideHandY + option.y + 65 : option.y + 65;
      gsap.to(guideHand, {
        duration: 1,
        x: guideHand.x + 10,
        y: guideHand.y + 10,
        repeat: -1,
        yoyo: true,
      });

      // 裁剪出一个圆孔
      let x = option?.x + option?.radius;
      let y = option?.y + option?.radius;
      let radius = option?.radius; // 对于圆形和椭圆

      let blur = option?.blur;
      mask.beginHole();
      mask.drawCircle(x, y, radius);
      mask.endHole();
      mask.endFill();
      dom.addChild(guideHand);
    } else {
      // 裁剪出一个圆孔
      let x = option?.x;
      let y = option?.y;
      let w = option.w;
      let h = option.h;
      let radius = option?.radius; // 对于圆形和椭圆
      console.log("XXXXXXXXXXXXXXXXXXX", x, y, w, h);
      guideHand.x = option?.guideHandX ? option.guideHandX + option.x + 65 : option.x + 65;
      guideHand.y = option?.guideHandY ? option.guideHandY + option.y + 65 : option.y + 65;
      gsap.to(guideHand, {
        duration: 1,
        x: guideHand.x + 10,
        y: guideHand.y + 10,
        repeat: -1,
        yoyo: true,
      });

      mask.beginHole();
      mask.drawRoundedRect(x, y, w, h, radius);
      mask.endHole();
      mask.endFill();
      dom.addChild(guideHand);
    }

    dom.mask = mask;
    // const _dom = _v.f_blurFilter(dom, { blur })

    return dom;
  },
  c_setBtnLoading: (pageDom, _btnDomName, _loadDomName, bool) => {
    const btnDom = pageDom.getChildByID(_btnDomName);
    const loadingDom = pageDom.getChildByID(_loadDomName);
    if (bool) {
      btnDom.visible = false;
      loadingDom.visible = true;
    } else {
      btnDom.visible = true;
      loadingDom.visible = false;
    }
  },
  m_tooltip: (content, text, option) => {
    // 创建工具提示容器
    let tooltip = document.createElement("div");
    tooltip.style.position = "absolute";
    tooltip.style.marginTop = option?.marginTop ? option.marginTop : 0;
    tooltip.style.marginLeft = option?.marginLeft ? option.marginLeft : 0;
    tooltip.style.fontSize = "13px";
    tooltip.style.backgroundColor = "#fff";
    tooltip.style.color = "#111";
    tooltip.style.padding = "10px";
    tooltip.style.borderRadius = "5px";
    tooltip.style.pointerEvents = "none";
    tooltip.style.display = "none";
    tooltip.style.fontFamily = "pFontM";
    tooltip.style.whiteSpace = "pre-line"; // 使 \n 生效
    tooltip.innerText = text;
    document.body.appendChild(tooltip);

    const target = new Layout(content);
    target.interactive = true;

    target.onpointerup = (event) => {
      tooltip.style.display = "block";
      updateTooltipPosition(event);
      event.stopPropagation();
    };
    target.addEventListener("pointerup", (event) => {
      event.stopPropagation();
    });
    target.on("pointerdown", (event) => {
      event.stopPropagation(); // 阻止事件冒泡
    });

    _v.gameApp.stage.addEventListener("pointerup", (event) => {
      tooltip.style.display = "none";
    });
    let fill = option?.fill ? option.fill : false;
    // 更新工具提示位置
    function updateTooltipPosition(event) {
      const newPosition = event.data.global;
      if (fill) {
        tooltip.style.left = "0px";
        tooltip.style.top = newPosition.y + 10 + "px";
      } else {
        tooltip.style.left = newPosition.x + 10 - tooltip.offsetWidth + "px";
        tooltip.style.top = newPosition.y + 10 + "px";
      }
    }
    return target;
  },
};
