const audioContext = new (window.AudioContext || window.webkitAudioContext)();
window["bgm"] = null;
window["effect"] = null;
window["effectGainNode"] = audioContext.createGain();
window["bgmGainNode"] = audioContext.createGain();
function initializeAudio(url) {
  return new Promise((resolve, reject) => {
    console.log("audioContext", audioContext);
    fetch(url)
      .then((response) => response.arrayBuffer())
      .then((data) => audioContext.decodeAudioData(data))
      .then((buffer) => {
        resolve(buffer);
      });
  });
}

function playHitSound(hitSoundBuffer, loop = false, gain) {
  const source = audioContext.createBufferSource();
  source.buffer = hitSoundBuffer;
  source.loop = loop; // Set loop property here
  source.connect(window[gain]);
  window[gain].connect(audioContext.destination);
  source.start(0);
  return source;
}
const effectUrl = "/assets/music/collet.mp3";
const effect = await initializeAudio(effectUrl);
const bgmUrl = "/assets/music/bgm.mp3";
const bgm = await initializeAudio(bgmUrl);
export default {
  music_effect: async (volume=1) => {
    if(!effect) {
      return
    }
    window["effect"] = playHitSound(effect, false, "effectGainNode");
    if(window['effectGainNode']) {
      window['effectGainNode'].gain.value = volume;
    }
  },
  music_bgm: async (volume=1) => {
    if(!bgm) {
      return
    }
    if (window["bgm"]) {
      window["bgm"].stop();
      window["bgm"].disconnect(); // Disconnect the source to free up resources
      window["bgm"] = null
    }
    const loop = true;
    window["bgm"] = playHitSound(bgm, loop, "bgmGainNode");
    if(window['bgmGainNode']) {
      window['bgmGainNode'].gain.value = volume;
    }
  },
};
