import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const gameVuex = createNamespacedHelpers("game");
const userVuex = createNamespacedHelpers("user");
import { Sprite, Text, Graphics, Assets, NineSlicePlane, TilingSprite, Texture } from "pixi.js";
const ratio = 0.82;
const scrollWidth = parseInt(_v.screenWidth * ratio);
const scrollHeight = _v.screenHeight - 225;
const scrollItemWidth = scrollWidth / 2;
const scrollItemHeight = 225;
Assets.load("market-title-bg");
await Assets.loadBundle("mine");
// await _v.m_loadMinerAnimal("/assets/animation", "chaigong_1", 10, "idle");
// await _v.m_loadMinerAnimal("/assets/animation", "chaigong_2", 10, "idle");
// await _v.m_loadMinerAnimal("/assets/animation", "chaigong_3", 10, "idle");
// await _v.m_loadMinerAnimal("/assets/animation", "chaigong_4", 10, "idle");
await Assets.loadBundle("market");
const isLoading = _v.c_listLoading();
const marketPageComponent = _v.c_marketPage();
console.log("marketPageComponent", marketPageComponent);
export default {
  name: "market",
  inject: ["closePage", "setPageRecruitShow"],
  computed: {
    ...gameVuex.mapState(["gameApp", "marketList", "marketListPage", "marketListTotal", "myMarketList", "myMarketListPage", "myMarketListTotal", "selectMinerInfo"]),
    ...userVuex.mapState(["gameInfo", "accountInfo", "coinInfo"]),
    isRender() {
      return this.$parent.pageMarketShow;
    }
  },
  watch: {
    isRender: {
      async handler(bool) {
        // console.log("666666666", bool);
        if (bool) {
          this.initScreen();
          if (this.coinInfo.colletTotalNum > 0) {
            await this.getAccountInfo();
            this.$refs.assetsInfo1.renderDom({
              coin: true,
              diamond: true,
              type: "refresh"
            });
          } else {
            this.selectItem = null;
          }
        }
      },
      immediate: true
    },
    "selectTabInfo.asset": {
      async handler(newVal, oldVal) {
        if (newVal != oldVal) {
          if (this.selectMTabInfo.value == 0) {
            this.setMarketListPage({
              page: 1
            });
          } else {
            this.setMyMarketListPage({
              page: 1
            });
          }
          this.setListLoading(true);
          this.initScrollBox();
          this.loadMarketList();
          this.setBtnSellShow();
        }
      },
      deep: true
    },
    "selectMTabInfo.value": {
      async handler(newVal, oldVal) {
        if (newVal != oldVal) {
          if (newVal == 0) {
            this.setMarketListPage({
              page: 1
            });
          } else {
            this.setMyMarketListPage({
              page: 1
            });
          }
          this.setListLoading(true);
          this.initScrollBox();
          this.loadMarketList();
          this.setBtnSellShow();
        }
      },
      deep: true
    }
  },
  data() {
    const mTabList = [{
      label: "MARKET",
      value: 0
    }, {
      label: "MY ORDER",
      value: 1
    }];
    const tabList = [{
      label: "MINER",
      value: 0,
      asset: "miner"
    }, {
      label: "MINE",
      value: 1,
      asset: "mine"
    }, {
      label: "COIN",
      value: 2,
      asset: "coin"
    }];
    return {
      currentScreen: null,
      pageDom: null,
      tabList,
      isTab: tabList[0].value,
      selectTabInfo: tabList[0],
      popMyMarketShow: false,
      mTabList,
      isMTab: mTabList[0].value,
      selectMTabInfo: mTabList[0],
      popBuyOption: {
        show: false,
        content: "Should I buy this miner"
      },
      selectRow: null,
      popDelistOption: {
        show: false,
        content: "Whether to Unlist"
      },
      popMyMineShow: false,
      popCoinSellShow: false,
      popMyMarketDom: null,
      popMyMineDom: null,
      popCoinSellDom: null,
      popBuyDom: null,
      popDelistDom: null,
      popMarketInfoDom: null,
      popMarketInfoShow: false,
      minerScrollBox: null,
      mineScrollBox: null,
      coinScrollBox: null,
      selectItem: null
    };
  },
  // async beforeDestroy() {
  //   this.pageDom?.destroy();
  //   await this.m_clearCurrentScreen(this.currentScreen);
  // },
  methods: {
    ...gameVuex.mapActions(["getMinerList", "getMarketList", "getMyMarketList", "marketBuy", "removeMarket", "bindWallet"]),
    ...gameVuex.mapMutations(["setMarketListPage", "setMyMarketListPage", "setSelectMinerInfo"]),
    ...userVuex.mapActions(["getAccountInfo"]),
    setListLoading(bool) {
      try {
        const scrollDom = this.pageDom.content.getByID("market_list_scrolls");
        if (bool) {
          isLoading.show();
          scrollDom.visible = false;
        } else {
          isLoading.hide();
          scrollDom.visible = true;
        }
      } catch (err) {}
    },
    initScreen() {
      this.$nextTick(async () => {
        this.$refs.assetsInfo1.renderDom({
          coin: true,
          diamond: true,
          type: "refresh"
        });
        await this.setScreen();
      });
    },
    async setScreen() {
      if (!_v[`page_market`]) {
        _v[`page_market`] = this.m_pageDom(this.renderDom());
        this.gameApp.stage.addChild(_v[`page_market`]);
      }
      _v[`page_market`].visible = true;
      _v[`page_market`].zIndex = this.gameApp.stage.children.length + 1;
      this.pageDom = _v[`page_market`];
      this.setDom();
      this.setBtnSellShow();
      this.loadMarketList();
    },
    setBtnSellShow() {
      const market_list_own = this.pageDom.content.getByID("market_list_own");
      const market_list_text = this.pageDom.content.getByID("market_list_text");
      if (this.selectMTabInfo.value === 0) {
        market_list_own.visible = true;
      } else {
        market_list_own.visible = false;
      }
      switch (this.selectTabInfo.asset) {
        case "miner":
          market_list_text.children[0].text = this.$lang("I want to sell miner");
          break;
        case "mine":
          market_list_text.children[0].text = this.$lang("I want to sell mine");
          break;
        case "coin":
          market_list_text.children[0].text = this.$lang("I want to sell coin");
          break;
      }
    },
    _setMarketListPage(initPage) {
      let list = [];
      let total = 0;
      let page = null;
      let perPage = 10;
      if (this.selectMTabInfo.value === 0) {
        list = this.marketList;
        total = this.marketListTotal;
        perPage = this.marketListPage.perPage;
        page = initPage ? initPage : this.marketListPage.page;
      } else {
        list = this.myMarketList;
        total = this.myMarketListTotal;
        perPage = this.myMarketListPage.perPage;
        page = initPage ? initPage : this.myMarketListPage.page;
      }
      const market_list_page = this.pageDom.content.getByID("market_list_page");
      // console.log("page, total, perPage", page, total, perPage);
      if (list && list.length > 0 && total > perPage) {
        market_list_page.visible = true;
        marketPageComponent.show(page, total, perPage, async _page => {
          if (page === _page) {
            return;
          }
          this.setListLoading(true);
          if (this.selectMTabInfo.value === 0) {
            this.setMarketListPage({
              page: _page
            });
          } else {
            this.setMyMarketListPage({
              page: _page
            });
          }
          this.loadMarketList();
          this.initScrollBox();
          this.setListLoading(true);
        });
      } else {
        market_list_page.visible = false;
      }
      this.setMarketListHeightAndScrollBoxLocation();
    },
    initScrollBox() {
      this.selectTabInfo.asset == 'miner' && this.minerScrollBox && this.minerScrollBox.scrollTop();
      this.selectTabInfo.asset == 'mine' && this.mineScrollBox && this.mineScrollBox.scrollTop();
      this.selectTabInfo.asset == 'coin' && this.coinScrollBox && this.coinScrollBox.scrollTop();
    },
    setDom() {
      this.pageDom.sortableChildren = true;
      console.log("this.pageDom", this.pageDom);
      const market_head = this.pageDom.getChildByID("market_head");
      const market_list = this.pageDom.getChildByID("market_list");
      const market_page = this.pageDom.getChildByID("market_page");
      const market_back = this.pageDom.getChildByID("market_back");
      const market_coin = this.pageDom.getChildByID("market_coin");
      market_page.sortableChildren = true;
      market_head.zIndex = 2;
      market_list.zIndex = 1;
      console.log("market_back", market_back);
      market_coin.zIndex = 3;
      market_back.zIndex = 3;
    },
    loadMarketMinerItemInfo() {
      const pageDom = _v[`page_market`];
      const scrollDom = pageDom.content.getByID("market_list_scroll_miner");
      const listDom = scrollDom.children[0].children[1].children;
      let list = null;
      if (this.selectMTabInfo.value === 0) {
        list = this.marketList;
      } else {
        list = this.myMarketList;
      }
      if (!list) return;
      listDom.map((itm, index) => {
        if (index >= list.length) {
          itm.visible = false;
          return;
        }
        itm.visible = true;
        const item = list[index];
        if (!item) return;
        const market_item_goods_miner = itm.content.getByID("market_item_goods_miner");
        market_item_goods_miner.children[0].textures = _v[`${this.setMiner(item.star, "img")}-idle`];
        market_item_goods_miner.children[0].play();
        const market_item_goods_star = itm.content.getByID("market_item_goods_star");
        market_item_goods_star.children.map((_itm, _idx) => {
          _itm.visible = _idx <= item.star - 1;
        });
        const market_item_goods_level = itm.content.getByID("market_item_goods_level");
        market_item_goods_level.children[0].text = `Lv.${item.level}`;
        const market_item_name = itm.content.getByID("market_item_name");
        market_item_name.children[0].text = `${this.setMiner(item.star, "name")}`;
        const market_item_pro = itm.content.getByID("market_item_pro");
        market_item_pro.children[0].text = `Power: ${this.toPre(item.power * this.powerTime, 0)}/h`;
        const market_item_id = itm.content.getByID("market_item_id");
        market_item_id.children[0].text = `ID: ${item.asset_id}`;
        const market_item_tag = itm.content.getByID("market_item_tag");
        const market_item_tag_main = itm.content.getByID("market_item_tag_main");
        if (this.selectMTabInfo.value == 0) {
          market_item_tag.visible = false;
        } else if (this.selectMTabInfo.value == 1) {
          market_item_tag.visible = true;
          if (item.trade === 0) {
            market_item_tag_main.children[0].text = "Unsold";
            market_item_tag_main.children[0].style.fill = "#fff";
          } else if (item.trade === 1) {
            market_item_tag_main.children[0].text = "Pending";
            market_item_tag_main.children[0].style.fill = "#ffb400";
          } else if (item.trade === 2) {
            market_item_tag_main.children[0].text = "Sold";
            market_item_tag_main.children[0].style.fill = "#7bc616";
          }
        }
        const market_item_btns_main = itm.content.getByID("market_item_btns_main");
        const market_item_btns_main_1 = itm.content.getByID("market_item_btns_main_1");
        const market_item_price = itm.content.getByID("market_item_price");
        if (this.selectMTabInfo.value == 0) {
          const market_item_btns_text = itm.content.getByID("market_item_btns_text");
          market_item_btns_text.children[0].text = `${this.toPre(item.ton, 2)}`;
          market_item_btns_main.visible = true;
          market_item_btns_main_1.visible = false;
          market_item_price.visible = false;
        } else if (this.selectMTabInfo.value == 1) {
          market_item_btns_main.visible = false;
          if (item.trade === 1 || item.trade === 2) {
            const market_item_price_text = itm.content.getByID("market_item_price_text");
            market_item_price_text.children[0].text = `${this.toPre(item.ton, 2)}`;
            market_item_btns_main_1.visible = false;
            market_item_price.visible = true;
          } else {
            market_item_btns_main_1.visible = true;
            market_item_price.visible = false;
          }
        }
      });
    },
    loadMarketMineItemInfo() {
      const pageDom = _v[`page_market`];
      const scrollDom = pageDom.content.getByID("market_list_scroll_mine");
      const listDom = scrollDom.children[0].children[1].children;
      let list = null;
      if (this.selectMTabInfo.value === 0) {
        list = this.marketList;
      } else {
        list = this.myMarketList;
      }
      if (!list) return;
      listDom.map((itm, index) => {
        if (index >= list.length) {
          itm.visible = false;
          return;
        }
        itm.visible = true;
        const item = list[index];
        if (!item) return;
        console.log("itmitmitmitm", itm);
        const mine_item_main_info = itm.content.getByID("mine_item_main_info");
        console.log("mine_item_main_info", mine_item_main_info);
        const mine_item_main_info_title = itm.content.getByID("mine_item_main_info_title");
        mine_item_main_info_title.children[0].text = this.setMine(item.type_id, "name");
        const mine_item_main_info_reward_id = itm.content.getByID("mine_item_main_info_reward_id");
        mine_item_main_info_reward_id.children[0].text = `ID: ${item.asset_id}`;
        const mine_item_main_info_reward_title = itm.content.getByID("mine_item_main_info_reward_title");
        mine_item_main_info_reward_title.children[0].text = `${this.$lang("Capacity")}: ${item.cap}`;
        const mine_item_main_info_reward_num = itm.content.getByID("mine_item_main_info_reward_num");
        if ((item.acce - 1) * 100 > 0) {
          mine_item_main_info_reward_num.visible = true;
          mine_item_main_info_reward_num.children[0].text = `${this.$lang("Accelerate")} ${this.toPre((item.acce - 1) * 100, 2)}%`;
        } else {
          mine_item_main_info_reward_num.visible = false;
        }
        const mine_item_tag = itm.content.getByID("mine_item_tag");
        const mine_item_tag_main = itm.content.getByID("mine_item_tag_main");
        if (this.selectMTabInfo.value == 0) {
          mine_item_tag.visible = false;
        } else if (this.selectMTabInfo.value == 1) {
          mine_item_tag.visible = true;
          if (item.trade === 0) {
            mine_item_tag_main.children[0].text = "Unsold";
            mine_item_tag_main.children[0].style.fill = "#fff";
          } else if (item.trade === 1) {
            mine_item_tag_main.children[0].text = "Pending";
            mine_item_tag_main.children[0].style.fill = "#ffb400";
          } else if (item.trade === 2) {
            mine_item_tag_main.children[0].text = "Sold";
            mine_item_tag_main.children[0].style.fill = "#7bc616";
          }
        }
        const mine_item_main_info_btns = itm.content.getByID("mine_item_main_info_btns");
        const mine_item_main_info_btns_1 = itm.content.getByID("mine_item_main_info_btns_1");
        const mine_item_price = itm.content.getByID("mine_item_price");
        if (this.selectMTabInfo.value == 0) {
          const mine_item_main_info_btns_text = itm.content.getByID("mine_item_main_info_btns_text");
          mine_item_main_info_btns_text.children[0].text = `${this.toPre(item.ton, 2)}`;
          mine_item_main_info_btns.visible = true;
          mine_item_main_info_btns_1.visible = false;
          mine_item_price.visible = false;
        } else if (this.selectMTabInfo.value == 1) {
          mine_item_main_info_btns.visible = false;
          if (item.trade === 1 || item.trade === 2) {
            const market_item_price_text = itm.content.getByID("market_item_price_text");
            market_item_price_text.children[0].text = `${this.toPre(item.ton, 2)}`;
            mine_item_main_info_btns_1.visible = false;
            mine_item_price.visible = true;
          } else {
            mine_item_main_info_btns_1.visible = true;
            mine_item_price.visible = false;
          }
        }
      });
    },
    loadMarketCoinItemInfo() {
      const pageDom = _v[`page_market`];
      const scrollDom = pageDom.content.getByID("market_list_scroll_coin");
      const listDom = scrollDom.children[0].children[1].children;
      let list = null;
      if (this.selectMTabInfo.value === 0) {
        list = this.marketList;
      } else {
        list = this.myMarketList;
      }
      if (!list) return;
      listDom.map((itm, index) => {
        if (index >= list.length) {
          itm.visible = false;
          return;
        }
        itm.visible = true;
        const item = list[index];
        if (!item) return;
        const market_item_name_icon = itm.content.getByID("market_item_name_icon");
        market_item_name_icon.children[0].text = `Coin x${this.toPre(item.amount)}`;
        const market_item_btns_text = itm.content.getByID("market_item_btns_text");
        market_item_btns_text.children[0].text = `${this.toPre(item.ton, 2)}`;
        const market_item_tag = itm.content.getByID("market_item_tag");
        const market_item_tag_main = itm.content.getByID("market_item_tag_main");
        if (this.selectMTabInfo.value == 0) {
          market_item_tag.visible = false;
        } else if (this.selectMTabInfo.value == 1) {
          market_item_tag.visible = true;
          if (item.trade === 0) {
            market_item_tag_main.children[0].text = "Unsold";
            market_item_tag_main.children[0].style.fill = "#fff";
          } else if (item.trade === 1) {
            market_item_tag_main.children[0].text = "Pending";
            market_item_tag_main.children[0].style.fill = "#ffb400";
          } else if (item.trade === 2) {
            market_item_tag_main.children[0].text = "Sold";
            market_item_tag_main.children[0].style.fill = "#7bc616";
          }
        }
        const market_item_btns_main = itm.content.getByID("market_item_btns_main");
        const market_item_btns_main_1 = itm.content.getByID("market_item_btns_main_1");
        const market_item_price = itm.content.getByID("market_item_price");
        if (this.selectMTabInfo.value == 0) {
          const market_item_btns_text = itm.content.getByID("market_item_btns_text");
          market_item_btns_text.children[0].text = `${this.toPre(item.ton, 2)}`;
          market_item_btns_main.visible = true;
          market_item_btns_main_1.visible = false;
          market_item_price.visible = false;
        } else if (this.selectMTabInfo.value == 1) {
          market_item_btns_main.visible = false;
          if (item.trade === 1 || item.trade === 2) {
            const market_item_price_text = itm.content.getByID("market_item_price_text");
            market_item_price_text.children[0].text = `${this.toPre(item.ton, 2)}`;
            market_item_btns_main_1.visible = false;
            market_item_price.visible = true;
          } else {
            market_item_btns_main_1.visible = true;
            market_item_price.visible = false;
          }
        }
      });
    },
    setMarketListHeightAndScrollBoxLocation() {
      const market_list_page = this.pageDom.content.getByID("market_list_page");
      const setScrollBoxHeight = height => {
        if (this.selectTabInfo.asset === 'miner') {
          this.minerScrollBox.height = height;
        } else if (this.selectTabInfo.asset === 'mine') {
          this.mineScrollBox.height = height;
        } else if (this.selectTabInfo.asset === 'coin') {
          this.coinScrollBox.height = height;
        }
      };
      if (this.selectMTabInfo.value == 0) {
        if (this.marketList && this.marketList.length > 0 && this.marketListTotal > this.marketListPage.perPage) {
          setScrollBoxHeight(scrollHeight - 80);
          market_list_page.setStyles({
            marginTop: scrollHeight + 12
          });
          console.log(8888);
        } else {
          console.log(666663);
          setScrollBoxHeight(scrollHeight - 40);
        }
      } else if (this.selectMTabInfo.value == 1) {
        if (this.myMarketList && this.myMarketList.length > 0 && this.myMarketListTotal > this.myMarketListPage.perPage) {
          setScrollBoxHeight(scrollHeight - 20);
          market_list_page.setStyles({
            marginTop: scrollHeight + 75
          });
          console.log(9999);
        } else {
          setScrollBoxHeight(scrollHeight);
          console.log(10101);
        }
      }
    },
    async loadMarketList() {
      this.setListLoading(true);
      const pageDom = this.pageDom.content;
      const market_list_box_1 = pageDom.getByID("market_list_box_1");
      const market_list_box = pageDom.getByID("market_list_box");
      const market_empty = pageDom.getByID("market_empty");
      const market_list_page = this.pageDom.content.getByID("market_list_page");
      const market_list_scroll_miner = pageDom.getByID("market_list_scroll_miner");
      market_list_scroll_miner.visible = false;
      const market_list_scroll_coin = pageDom.getByID("market_list_scroll_coin");
      market_list_scroll_coin.visible = false;
      const market_list_scroll_mine = pageDom.getByID("market_list_scroll_mine");
      market_list_scroll_mine.visible = false;
      if (this.selectMTabInfo.value == 0 && (this.selectTabInfo.asset === "mine" && !this.gameInfo.mineMarketOpen || this.selectTabInfo.asset === "coin" && !this.gameInfo.coinMarketOpen)) {
        this.setListLoading(false);
        market_list_page.visible = false;
        market_empty.visible = false;
        market_list_box.visible = false;
        market_list_box_1.visible = true;
        return;
      } else {
        market_list_box.visible = true;
        market_list_box_1.visible = false;
      }
      let list = null;
      let listTotal = null;
      let listPage = null;
      if (this.selectMTabInfo.value == 0) {
        await this.getMarketList({
          asset: this.selectTabInfo.asset
        });
        list = this.marketList;
        listTotal = this.marketListTotal;
        listPage = this.marketListPage;
        console.log("this.marketListTotal", this.marketListTotal);
      } else {
        await this.getMyMarketList({
          asset: this.selectTabInfo.asset
        });
        list = this.myMarketList;
        listTotal = this.myMarketListTotal;
        listPage = this.myMarketListPage;
        console.log("this.myMarketListTotal", this.myMarketListTotal);
      }
      if (list.length <= 0) {
        market_empty.visible = true;
      } else {
        market_empty.visible = false;
        switch (this.selectTabInfo.asset) {
          case "miner":
            this.loadMarketMinerItemInfo();
            market_list_scroll_miner.visible = true;
            break;
          case "mine":
            this.loadMarketMineItemInfo();
            market_list_scroll_mine.visible = true;
            break;
          case "coin":
            this.loadMarketCoinItemInfo();
            market_list_scroll_coin.visible = true;
            break;
        }
      }
      this._setMarketListPage();
      this.setListLoading(false);
    },
    marketItemMinerDom() {
      return {
        id: `marketItemMiner_${new Date().getTime()}`,
        content: {
          market_item_bg: {
            content: this.sf_taskItemBg({
              width: scrollItemWidth * 4 - 40,
              height: scrollItemHeight * 4 - 40
            })
          },
          market_item_goods: {
            content: {
              market_item_goods_bg: {
                content: this.sf_taskItemImg({
                  width: scrollItemWidth * 4 - 140,
                  height: 430
                })
              },
              market_item_goods_miner: {
                content: this.m_setSpineAnmationsSprite(`${this.setMiner(1, "img")}-idle`, {
                  loop: true,
                  isPlay: true,
                  animationSpeed: 0.25
                })
              },
              market_item_goods_star: {
                content: {
                  market_item_star_label: Array.from({
                    length: this.gameInfo.maxMinerStar
                  }, () => ({
                    content: Sprite.from(this.m_getRes("element").textures["icon-star.png"]),
                    styles: globalStyles.market_item_star_label
                  }))
                },
                styles: globalStyles.market_item_goods_star
              },
              market_item_goods_level: {
                content: `Lv.--`
              },
              market_item_tag: {
                content: {
                  market_item_tag_bg: {
                    content: " "
                  },
                  market_item_tag_main: {
                    content: " ",
                    styles: globalStyles.market_item_tag_main
                  }
                },
                styles: globalStyles.market_item_tag
              }
            }
          },
          market_item_name: {
            content: `--`
          },
          market_item_pro: {
            content: `Power: --`
          },
          market_item_btns: {
            content: {
              market_item_btns_main: {
                content: {
                  market_item_btns_con: this.m_btn({
                    content: {
                      market_item_btns_bg: {
                        content: this.sf_btnBg({
                          width: scrollItemWidth * 4 - 110,
                          height: 150
                        }),
                        styles: globalStyles.market_item_btns_bg
                      },
                      market_item_btns_main_f: {
                        content: {
                          market_item_btns_icon: {
                            content: Sprite.from(this.m_getRes("element").textures["icon-ton.png"]),
                            styles: globalStyles.market_item_btns_icon
                          },
                          market_item_btns_text: {
                            content: `--`,
                            styles: globalStyles.market_item_btns_text
                          }
                        },
                        styles: globalStyles.market_item_btns_main_f
                      }
                    },
                    styles: globalStyles.market_item_btns_con
                  }, async () => {
                    const item = this.selectItem;
                    console.log("itemitemitemitem", item);
                    if (!item) return;
                    await this.onBuy(item);
                  })
                },
                styles: globalStyles.market_item_btns_main
              },
              market_item_btns_main_1: {
                content: {
                  market_item_btns_con_1: this.m_btn({
                    content: {
                      market_item_btns_bg: {
                        content: this.sf_btnBg({
                          width: scrollItemWidth * 4 - 110,
                          height: 150
                        }),
                        styles: globalStyles.market_item_btns_bg
                      },
                      market_item_btns_main_x: {
                        content: {
                          market_item_btns_text_x: {
                            content: this.$lang("Unlist"),
                            styles: globalStyles.market_item_btns_text
                          }
                        },
                        styles: globalStyles.market_item_btns_main_x
                      }
                    },
                    styles: globalStyles.market_item_btns_con_1
                  }, async () => {
                    const item = this.selectItem;
                    console.log("itemitemitemitem", item);
                    if (!item) return;
                    this.onDelist(item);
                  })
                },
                styles: globalStyles.market_item_btns_main_1
              },
              market_item_price: {
                content: {
                  market_item_price_main: {
                    content: {
                      market_item_price_icon: {
                        content: Sprite.from(this.m_getRes("element").textures["icon-ton.png"])
                      },
                      market_item_price_text: {
                        content: `--`
                      }
                    }
                  }
                }
              }
            },
            styles: globalStyles.market_item_btns
          },
          market_item_id: {
            content: `ID: --`
          }
        },
        globalStyles
      };
    },
    marketItemMineDom() {
      return {
        id: "market_item",
        content: {
          mine_item_bg: {
            content: this.sf_taskItemBg({
              width: scrollWidth * 4,
              height: 100 * 4
            }),
            styles: globalStyles.mine_item_bg
          },
          mine_item_main: {
            content: {
              mine_item_main_head: {
                content: {
                  mine_item_main_head_bg: {
                    content: this.sf_taskItemImg({
                      width: 300,
                      height: 300
                    }),
                    styles: globalStyles.mine_item_main_head_bg
                  },
                  mine_item_main_head_img: {
                    content: Sprite.from(this.setMine(0, "thumbnail")),
                    styles: globalStyles.mine_item_main_head_img
                  }
                },
                styles: globalStyles.mine_item_main_head
              },
              mine_item_main_info: {
                content: {
                  mine_item_main_info_title: {
                    content: "--",
                    styles: globalStyles.mine_item_main_info_title
                  },
                  mine_item_main_info_reward: {
                    content: {
                      mine_item_main_info_reward_id: {
                        content: `ID: --`,
                        styles: globalStyles.mine_item_main_info_reward_id
                      },
                      mine_item_main_info_reward_title: {
                        content: `${this.$lang("Capacity")}:--`,
                        styles: globalStyles.mine_item_main_info_reward_title
                      },
                      // mine_item_main_info_reward_num: (item.acce - 1) * 100 > 0 && {
                      //   content: `${this.$lang("Accelerate")} --`,
                      //   styles: globalStyles.mine_item_main_info_reward_num,
                      // },
                      mine_item_main_info_reward_num: {
                        content: `${this.$lang("Accelerate")} --`,
                        styles: globalStyles.mine_item_main_info_reward_num
                      }
                    },
                    styles: globalStyles.mine_item_main_info_reward
                  }
                },
                styles: globalStyles.mine_item_main_info
              },
              mine_item_main_info_btns: {
                content: {
                  mine_item_main_info_btns_main: this.m_btn({
                    content: {
                      mine_item_main_info_btns_bg: {
                        content: this.sf_btnBg({
                          width: 380,
                          height: 150
                        }),
                        styles: globalStyles.mine_item_main_info_btns_bg
                      },
                      mine_item_main_info_btns_main: {
                        content: {
                          mine_item_main_info_btns_icon: {
                            content: Sprite.from(this.m_getRes("element").textures["icon-ton.png"]),
                            styles: globalStyles.mine_item_main_info_btns_icon
                          },
                          mine_item_main_info_btns_text: {
                            content: `--`,
                            styles: globalStyles.mine_item_main_info_btns_text
                          }
                        },
                        styles: globalStyles.mine_item_main_info_btns_main
                      }
                    },
                    styles: globalStyles.mine_item_main_info_btns_main
                  }, async () => {
                    const item = this.selectItem;
                    if (!item) return;
                    await this.onBuy(item);
                  })
                },
                styles: globalStyles.mine_item_main_info_btns
              },
              mine_item_main_info_btns_1: {
                content: {
                  mine_item_main_info_btns_main: this.m_btn({
                    content: {
                      mine_item_main_info_btns_bg: {
                        content: this.sf_btnBg({
                          width: 380,
                          height: 150
                        }),
                        styles: globalStyles.mine_item_main_info_btns_bg
                      },
                      mine_item_main_info_btns_main_x: {
                        content: {
                          mine_item_main_info_btns_text_x: {
                            content: this.$lang("Unlist"),
                            styles: globalStyles.mine_item_main_info_btns_text
                          }
                        },
                        styles: globalStyles.mine_item_main_info_btns_main_x
                      }
                    },
                    styles: globalStyles.mine_item_main_info_btns_main
                  }, async () => {
                    const item = this.selectItem;
                    console.log("itemitemitemitem", item);
                    this.onDelist(item);
                  })
                },
                styles: globalStyles.mine_item_main_info_btns_1
              }
            },
            styles: globalStyles.mine_item_main
          },
          mine_item_tag: {
            content: {
              market_item_tag_bg: {
                content: " ",
                styles: globalStyles.market_item_tag_bg
              },
              mine_item_tag_main: {
                content: " ",
                styles: globalStyles.mine_item_tag_main
              }
            },
            styles: globalStyles.mine_item_tag
          },
          mine_item_price: {
            content: {
              market_item_price_main: {
                content: {
                  market_item_price_icon: {
                    content: Sprite.from(this.m_getRes("element").textures["icon-ton.png"])
                  },
                  market_item_price_text: {
                    content: `--`
                  }
                },
                styles: globalStyles.market_item_price_main
              }
            },
            styles: globalStyles.mine_item_price
          }
        },
        globalStyles
      };
    },
    marketItemCoinDom() {
      return {
        id: "market_item",
        content: {
          market_item_bg: {
            content: this.sf_taskItemBg({
              width: scrollItemWidth * 4 - 40,
              height: scrollItemHeight * 4 - 80
            })
          },
          market_item_goods: {
            content: {
              market_item_goods_bg: {
                content: this.sf_taskItemImg({
                  width: scrollItemWidth * 4 - 140,
                  height: 430
                }),
                styles: globalStyles.market_item_goods_bg
              },
              market_item_goods_gold: {
                content: Sprite.from(this.m_getRes("element").textures["icon-market-coin.png"]),
                styles: globalStyles.market_item_goods_gold
              },
              market_item_tag: {
                content: {
                  market_item_tag_bg: {
                    content: " "
                  },
                  market_item_tag_main: {
                    content: " ",
                    styles: globalStyles.market_item_tag_main
                  }
                },
                styles: globalStyles.market_item_tag
              }
            },
            styles: globalStyles.market_item_goods
          },
          market_item_name_icon: {
            content: `Coin x --`
          },
          market_item_btns: {
            content: {
              market_item_btns_main: {
                content: {
                  market_item_btns_con: this.m_btn({
                    content: {
                      market_item_btns_bg: {
                        content: this.sf_btnBg({
                          width: scrollItemWidth * 4 - 110,
                          height: 150
                        }),
                        styles: globalStyles.market_item_btns_bg
                      },
                      market_item_btns_main_f: {
                        content: {
                          market_item_btns_icon: {
                            content: Sprite.from(this.m_getRes("element").textures["icon-ton.png"]),
                            styles: globalStyles.market_item_btns_icon
                          },
                          market_item_btns_text: {
                            content: `--`,
                            styles: globalStyles.market_item_btns_text
                          }
                        },
                        styles: globalStyles.market_item_btns_main_f
                      }
                    },
                    styles: globalStyles.market_item_btns_con
                  }, async () => {
                    const item = this.selectItem;
                    console.log("itemitemitemitem", item);
                    if (!item) return;
                    await this.onBuy(item);
                  })
                },
                styles: globalStyles.market_item_btns_main
              },
              market_item_btns_main_1: {
                content: {
                  market_item_btns_con_1: this.m_btn({
                    content: {
                      market_item_btns_bg: {
                        content: this.sf_btnBg({
                          width: scrollItemWidth * 4 - 110,
                          height: 150
                        }),
                        styles: globalStyles.market_item_btns_bg
                      },
                      market_item_btns_main_x: {
                        content: {
                          market_item_btns_text_x: {
                            content: this.$lang("Unlist"),
                            styles: globalStyles.market_item_btns_text
                          }
                        },
                        styles: globalStyles.market_item_btns_main_x
                      }
                    },
                    styles: globalStyles.market_item_btns_con_1
                  }, async () => {
                    const item = this.selectItem;
                    console.log("itemitemitemitem", item);
                    if (!item) return;
                    this.onDelist(item);
                  })
                },
                styles: globalStyles.market_item_btns_main_1
              },
              market_item_price: {
                content: {
                  market_item_price_main: {
                    content: {
                      market_item_price_icon: {
                        content: Sprite.from(this.m_getRes("element").textures["icon-ton.png"])
                      },
                      market_item_price_text: {
                        content: `--`
                      }
                    }
                  }
                }
              }
            },
            styles: {
              ...globalStyles.market_item_btns,
              ...globalStyles.market_item_btns_coin
            }
          }
        },
        globalStyles
      };
    },
    marketListMinerDom(init = 10) {
      const newList = Array.from({
        length: init
      }, (_, index) => index);
      const _newList = newList.map((it, idx) => {
        return this.m_btn(this.marketItemMinerDom(), () => {
          if (this.selectMTabInfo.value === 0) {
            this.selectItem = this.marketList[idx];
          } else {
            this.selectItem = this.myMarketList[idx];
          }
          console.log("this.selectItem", this.selectItem);
        }, {
          width: scrollItemWidth,
          height: scrollItemHeight
        });
      });
      const pageDom = _v[`page_market`]?.content;
      if (pageDom) {
        const market_list_scroll_miner = pageDom.getByID("market_list_scroll_miner");
        market_list_scroll_miner.visible = true;
      }
      this.minerScrollBox = this.c_scrollBox(_newList, {
        width: scrollWidth,
        height: scrollHeight - 20,
        vertPadding: 10
      });
      return this.minerScrollBox;
    },
    marketListCoinDom(init = 10) {
      const newList = Array.from({
        length: init
      }, (_, index) => index);
      const _newList = newList.map((it, idx) => {
        return this.m_btn(this.marketItemCoinDom(), () => {
          if (this.selectMTabInfo.value === 0) {
            this.selectItem = this.marketList[idx];
          } else {
            this.selectItem = this.myMarketList[idx];
          }
          console.log("this.selectItem", this.selectItem);
        }, {
          width: scrollItemWidth,
          height: scrollItemHeight - 10
        });
      });
      this.coinScrollBox = this.c_scrollBox(_newList, {
        width: scrollWidth,
        height: scrollHeight - 20,
        vertPadding: 10
      });
      return this.coinScrollBox;
    },
    marketListMineDom(init = 10) {
      let newList = [];
      const list = this.marketList;
      if (list) {
        newList = list;
      } else {
        newList = Array.from({
          length: init
        }, (_, index) => index);
      }
      const _newList = newList.map((it, idx) => {
        return this.m_btn(this.marketItemMineDom(), () => {
          if (this.selectMTabInfo.value === 0) {
            this.selectItem = this.marketList[idx];
          } else {
            this.selectItem = this.myMarketList[idx];
          }
          console.log("this.selectItem", this.selectItem);
        }, {
          width: scrollWidth,
          height: 108
        });
      });
      this.mineScrollBox = this.c_scrollBox(_newList, {
        width: scrollWidth,
        height: scrollHeight - 20,
        vertPadding: 10
      });
      return this.mineScrollBox;
    },
    renderDom() {
      return {
        content: {
          market_page: {
            content: {
              market_bg: {
                content: this.m_tilingSprite("bg5.jpg")
              },
              market_coin: {
                content: this.$refs.assetsInfo1.renderDom({
                  coin: true,
                  diamond: true
                })
              },
              market_head: {
                content: {
                  market_head_bg: {
                    content: Sprite.from("market-title-bg"),
                    styles: this.m_isIOS() ? {
                      ...globalStyles.market_head_bg,
                      marginTop: -10
                    } : globalStyles.market_head_bg
                  },
                  market_m_tabs: {
                    content: this.c_main_tab(this.mTabList, this.isMTab, item => {
                      console.log("itemitem", item);
                      this.isMTab = item.value;
                      this.selectMTabInfo = item;
                    }, {
                      width: 150
                    })
                  },
                  market_info: this.m_btn({
                    content: Sprite.from(this.m_getRes("element").textures["icon-question.png"]),
                    styles: globalStyles.market_info
                  }, () => {
                    this.popMarketInfoShow = true;
                  })
                }
              },
              market_list: {
                content: {
                  market_list_bg: {
                    content: this.sf_marketGoods({
                      width: this.screenWidth * 4 - 160,
                      height: this.screenHeight * 4 - 370
                    })
                  },
                  market_tabs: {
                    content: this.c_tab(this.tabList, this.isTab, item => {
                      console.log("itemitem", item);
                      this.isTab = item.value;
                      this.selectTabInfo = item;
                    }, {
                      width: this.scrollWidth / 3
                    })
                  },
                  common_loading: isLoading.render(),
                  market_list_box: {
                    content: {
                      market_list_scrolls: {
                        content: {
                          market_list_scroll_miner: {
                            content: this.marketListMinerDom(),
                            styles: globalStyles.market_list_scroll
                          },
                          market_list_scroll_mine: {
                            content: this.marketListMineDom(),
                            styles: globalStyles.market_list_scroll
                          },
                          market_list_scroll_coin: {
                            content: this.marketListCoinDom(),
                            styles: globalStyles.market_list_scroll
                          }
                        }
                      },
                      market_list_page: {
                        content: {
                          market_list_page_main: {
                            content: marketPageComponent.render(1, 0, 10)
                          }
                        }
                      },
                      market_list_own: {
                        content: this.m_btn({
                          content: {
                            market_list_own_bg: {
                              content: this.sf_rankingOwnBg({
                                width: this.screenWidth * 4 - 200,
                                height: 290
                              }),
                              styles: globalStyles.market_list_own_bg
                            },
                            market_list_text: {
                              content: " ",
                              styles: globalStyles.market_list_text
                            }
                          },
                          styles: {
                            width: "100%",
                            height: "100%"
                          }
                        }, async () => {
                          if (this.selectTabInfo.asset === "miner") {
                            this.popMyMarketShow = true;
                          } else if (this.selectTabInfo.asset === "mine") {
                            this.popMyMineShow = true;
                          } else if (this.selectTabInfo.asset === "coin") {
                            console.log(3344);
                            this.popCoinSellShow = true;
                          }
                        }),
                        styles: globalStyles.market_list_own
                      }
                    },
                    styles: globalStyles.market_list_box
                  },
                  market_list_box_1: {
                    content: {
                      market_empty_1: {
                        content: this.c_comingsoon("Coming Soon", {
                          isBtn: false
                        }),
                        styles: globalStyles.market_empty_1
                      }
                    },
                    styles: {
                      ...globalStyles.market_list_box,
                      ...globalStyles.market_list_box_1
                    }
                  },
                  market_empty: {
                    content: this.c_empty(this.$lang("There's nothing at all."), {
                      isBtn: false
                    }, null),
                    styles: globalStyles.market_empty
                  }
                },
                styles: globalStyles.market_list
              },
              market_back: {
                content: this.c_btnBack(() => {
                  _v[`page_market`].visible = false;
                  this.$emit("update:show", false);
                })
              }
            }
          }
        },
        globalStyles
      };
    },
    async onBuy(item) {
      if (this.selectItem.asset == item.asset && this.selectItem.id == item.id) {
        this.selectRow = [];
        this.selectRow.push(item.id);
        await _v.m_ton();
        if (!this.accountInfo.wallet) {
          await this.bindWallet();
        }
        this.popBuyOption.content = "Confirm purchase of this item?";
        this.popBuyOption.show = true;
      }
    },
    onDelist(item) {
      if (this.selectItem.asset == item.asset && this.selectItem.id == item.id) {
        this.selectRow = [];
        this.selectRow.push(item.id);
        this.popDelistOption.show = true;
      }
    },
    async popMyMarketSubmit() {
      this.isTab = 0;
      this.selectTabInfo = {
        label: "MINER",
        value: 0,
        asset: "miner"
      };
      // await this.getMarketList({ asset: "miner" });
      this.loadMarketList();
    },
    async popDelistSubmit(callback) {
      const data = await this.removeMarket(this.selectRow[0]);
      if (!data) {
        this.$refs.marketDelistRef.setBtnLoading(false);
        return;
      }
      if (this.selectTabInfo.asset === "coin") {
        await this.getAccountInfo();
        this.$refs.assetsInfo1.renderDom({
          coin: true,
          diamond: true,
          type: "refresh"
        });
      }
      if (this.myMarketListPage.page > 1 && this.myMarketListTotal >= this.myMarketListPage.perPage) {
        this.setMyMarketListPage({
          page: this.myMarketListPage.page - 1
        });
      }
      // await this.getMyMarketList({ asset: this.selectTabInfo.asset });
      this.selectItem = null;
      this.popDelistOption.show = false;
      this.popDelistDom.visible = false;
      typeof callback == "function" && callback();
      this.loadMarketList();
    },
    async popBuySubmit(callback) {
      const result = await this.marketBuy(this.selectRow);
      if (!result || result.code > 0) {
        if (result && result.code === 10000) {
          this.setMarketListPage({
            page: 1
          });
          if (this.marketListPage.page > 1 && this.marketListTotal >= this.marketListPage.perPage) {
            this.setMarketListPage({
              page: this.marketListPage.page - 1
            });
          }
          // await this.getMarketList({ asset: this.selectTabInfo.asset });
          this.$refs.marketBuyRef.setBtnLoading(false);
          this.popBuyDom.visible = false;
          this.popBuyOption.show = false;
          this.loadMarketList();
        } else {
          this.$refs.marketBuyRef.setBtnLoading(false);
          return;
        }
      }
      // console.log("this.selectTabInfo.asset ", this.selectTabInfo.asset);
      if (this.selectTabInfo.asset === "coin") {
        await this.getAccountInfo();
        this.$refs.assetsInfo1.renderDom({
          coin: true,
          diamond: true,
          type: "refresh"
        });
      }
      if (this.marketListPage.page > 1 && this.marketListTotal >= this.marketListPage.perPage) {
        this.setMarketListPage({
          page: this.marketListPage.page - 1
        });
      }
      await this.getMarketList({
        asset: this.selectTabInfo.asset
      });
      this.selectItem = null;
      this.popBuyOption.show = false;
      this.popBuyDom.visible = false;
      typeof callback == "function" && callback();
      this.loadMarketList();
    },
    async popMyMineSubmit() {
      this.isTab = 1;
      this.selectTabInfo = {
        label: "MINE",
        value: 1,
        asset: "mine"
      };
      // await this.getMarketList({ asset: "mine" });
      this.loadMarketList();
    },
    async popCoinSellSubmit() {
      await this.getAccountInfo();
      // await this.getMarketList({ asset: "coin" });
      this.loadMarketList();
      this.$refs.assetsInfo1.renderDom({
        coin: true,
        diamond: true,
        type: "refresh"
      });
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (!_v[`page_market`]) {
        _v[`page_market`] = this.m_pageDom(this.renderDom());
        this.gameApp.stage.addChild(_v[`page_market`]);
      }
      _v[`page_market`].visible = false;
      this.pageDom = _v[`page_market`];
      this.setDom();
      this.setBtnSellShow();
    });
  }
};
const globalStyles = {
  market_page: {
    position: "leftTop",
    width: _v.screenWidth,
    height: _v.screenHeight,
    maxWidth: _v.screenWidth,
    maxHeight: _v.screenHeight,
    fontSize: 0,
    overflow: "hiddren"
  },
  market_bg: {
    maxWidth: "100%",
    position: "centerBottom"
  },
  market_diamond_coin: {
    marginTop: 15,
    position: "rightTop",
    marginRight: 135
  },
  market_coin: {
    marginTop: 15,
    position: "rightTop"
  },
  market_head: {
    position: "centerTop",
    height: 520,
    overflow: "hidden",
    marginTop: -10
  },
  market_head_bg: {
    // width: 100,
    position: "centerTop",
    maxWidth: _v.screenWidth,
    maxHeight: _v.screenWidth * 0.352
  },
  market_list: {
    position: "centerTop",
    marginTop: 70,
    height: _v.screenHeight - 50,
    overflow: "hidden"
  },
  market_list_bg: {
    position: "centerTop",
    scale: 0.25
  },
  market_m_tabs: {
    marginTop: 60,
    position: "centerTop"
  },
  market_tabs: {
    marginTop: 55,
    position: "centerTop"
  },
  market_list_box: {
    width: "100%",
    height: "100%"
  },
  market_list_scrolls: {
    width: "100%",
    height: "100%",
    visible: false
  },
  market_list_box_1: {
    position: "centerTop",
    visible: false
  },
  market_list_scroll: {
    marginTop: 95,
    position: "centerTop"
  },
  market_item: {
    // minWidth: "100%",
  },
  market_item_bg: {
    position: "centerTop",
    // maxWidth: "100%",
    scale: 0.25
  },
  market_item_goods: {
    position: "centerTop",
    marginTop: 11,
    width: "90%"
  },
  market_item_goods_bg: {
    position: "centerTop",
    scale: 0.25
  },
  market_item_goods_img: {
    position: "centerTop",
    marginTop: 10,
    scale: 0.25
  },
  market_item_goods_miner: {
    position: "centerTop",
    marginTop: 5,
    scale: 0.12
  },
  market_item_goods_mine: {
    position: "centerTop",
    marginTop: 15,
    scale: 0.25
  },
  market_item_goods_gold: {
    position: "centerTop",
    marginTop: -10,
    scale: 0.3
  },
  market_item_goods_tag: {
    background: "#232b36",
    color: "#676b71",
    fontSize: 12,
    fontFamily: "pFont",
    marginLeft: 15,
    paddingLeft: 6,
    paddingRight: 6,
    borderRadius: 30,
    marginTop: 5
  },
  market_item_goods_star: {
    position: "left",
    marginTop: 89,
    marginLeft: 15
  },
  market_item_star_label: {
    scale: 0.2,
    width: 16,
    height: 16
  },
  market_item_goods_level: {
    marginTop: 87,
    position: "right",
    fontSize: 16,
    fontFamily: "pFont",
    marginRight: 18,
    color: "#fff"
  },
  market_item_tag: {
    position: "leftTop",
    marginLeft: 15,
    marginTop: 8,
    visible: false,
    width: 60,
    height: 20
  },
  market_item_tag_bg: {
    background: "#000",
    opacity: 0.6,
    position: "center",
    width: 65,
    height: 20,
    borderRadius: 30
  },
  market_item_tag_main: {
    borderRadius: 30,
    fontSize: 13,
    fontFamily: "pFont",
    width: "100%",
    height: 20,
    textAlign: "center",
    position: "center"
  },
  market_item_name: {
    position: "centerTop",
    marginTop: 120,
    fontFamily: "pFont",
    fontSize: 15,
    fill: "#2e3443",
    width: "100%",
    textAlign: "center"
  },
  market_item_id: {
    width: "100%",
    position: "centerBottom",
    marginBottom: 16,
    textAlign: "center",
    fontSize: 13,
    color: "#11131e",
    fontFamily: "pFont"
  },
  market_item_name_icon: {
    position: "centerTop",
    marginTop: 130,
    fontFamily: "pFont",
    fontSize: 16,
    fill: "#2e3443",
    width: "100%",
    textAlign: "center"
  },
  market_item_pro: {
    position: "centerTop",
    marginTop: 136,
    fontFamily: "pFont",
    fontSize: 12,
    fill: "#7f83a5",
    width: "100%",
    textAlign: "center"
  },
  market_item_price: {
    width: "80%",
    position: "center",
    background: "#cbc6b5",
    height: 30,
    borderRadius: 6,
    visible: false
  },
  mine_item_price: {
    width: 90,
    position: "centerRight",
    background: "#cbc6b5",
    height: 30,
    borderRadius: 6,
    marginRight: 10,
    marginTop: 8,
    visible: false
  },
  market_item_price_main: {
    position: "center",
    height: 20
  },
  market_item_price_icon: {
    scale: 0.25,
    marginTop: 2,
    width: 25
  },
  market_item_price_text: {
    fontSize: 18,
    color: "#111",
    fontFamily: "pFont"
  },
  market_item_btns: {
    position: "centerBottom",
    marginBottom: 32,
    width: "100%",
    height: 40
  },
  market_item_btns_coin: {
    marginBottom: 24
  },
  market_item_btns_mine: {
    marginTop: 132,
    width: "100%",
    height: 40,
    overflow: "hidden"
  },
  market_item_btns_glod: {
    marginBottom: 20,
    bottom: 135,
    height: 40
  },
  market_item_btns_bg: {
    position: "center",
    scale: 0.25
  },
  market_item_btns_main: {
    position: "center",
    visible: false
  },
  market_item_btns_con: {
    position: "center"
  },
  market_item_btns_main_1: {
    position: "center",
    visible: false
  },
  market_item_btns_main_f: {
    position: "center"
  },
  market_item_btns_main_x: {
    position: "center"
  },
  market_item_btns_con_1: {
    position: "center"
  },
  market_item_btns_icon: {
    width: 28,
    height: 28,
    scale: 0.28,
    marginTop: 3,
    marginRight: -4
  },
  market_item_btns_text: {
    fill: "#fff",
    fontSize: 18,
    fontFamily: "pFont",
    stroke: "#11131e",
    strokeThickness: 2,
    lineJoin: "round",
    miterLimit: 100,
    dropShadow: true,
    dropShadowAngle: 90,
    dropShadowDistance: 1
  },
  market_list_page: {
    // maxHeight: 30,
    position: "centerTop",
    width: scrollWidth - 5,
    background: "#364254",
    marginTop: scrollHeight + 50
    // visible: false
  },

  market_list_page_main: {
    marginTop: 8,
    position: "center"
  },
  market_empty: {
    position: "centerTop",
    marginTop: 60,
    visible: false
  },
  market_empty_1: {
    marginTop: 80
  },
  market_list_own: {
    position: "centerBottom",
    height: 75,
    width: _v.screenWidth - 50,
    overflow: "hidden",
    marginBottom: 50
  },
  market_list_own_bg: {
    scale: 0.25
  },
  market_list_text: {
    position: "center",
    textAlign: "center",
    ..._v.fs_fontStyle({
      fz: 18,
      st: 2,
      ds: 1
    }),
    width: "100%",
    height: "100%"
  },
  mine_item_bg: {
    position: "center",
    maxWidth: "100%",
    scale: 0.25
  },
  mine_item_main: {
    position: "center",
    paddingLeft: 10
  },
  mine_item_main_head: {
    width: 75,
    height: 75,
    overflow: "hidden"
  },
  mine_item_main_head_bg: {
    scale: 0.25
  },
  mine_item_main_head_img: {
    position: "center",
    scale: 0.2
    // marginLeft: -3,
    // marginTop: -4,
  },

  mine_item_main_head_lock: {
    position: "center",
    scale: 0.25
  },
  mine_item_main_head_fee: {},
  mine_item_main_info: {
    width: scrollWidth - 100,
    height: 80,
    marginLeft: 5,
    paddingTop: 5
    // overflow: "hidden",
  },

  mine_item_main_info_title: {
    fontSize: 18,
    color: "#2e3443",
    fontFamily: "pFont"
  },
  mine_item_main_info_reward: {
    position: "leftTop",
    width: 450,
    height: "100%",
    marginTop: 22,
    marginLeft: 5
    // overflow: 'hidden'
  },

  mine_item_main_info_reward_id: {
    marginTop: 10,
    fontSize: 13,
    color: "#656d80",
    fontFamily: "pFont",
    display: "line"
  },
  mine_item_main_info_reward_num: {
    marginTop: 10,
    fontSize: 13,
    color: "#656d80",
    fontFamily: "pFont",
    display: "line"
  },
  mine_item_main_info_reward_title: {
    marginTop: 10,
    fontSize: 13,
    color: "#656d80",
    fontFamily: "pFont",
    display: "line"
  },
  mine_item_main_info_btns: {
    position: "rightBottom",
    width: 100,
    height: 40,
    marginBottom: 5,
    visible: false
  },
  mine_item_main_info_btns_1: {
    position: "rightBottom",
    width: 100,
    height: 40,
    marginBottom: 5,
    visible: false
  },
  mine_item_main_info_btns_bg: {
    scale: 0.25,
    position: "center"
  },
  mine_item_main_info_btns_main: {
    position: "center"
  },
  mine_item_main_info_btns_main_x: {
    position: "center"
  },
  mine_item_main_info_btns_icon: {
    width: 28,
    height: 28,
    scale: 0.28,
    marginTop: 2,
    marginRight: -2
  },
  mine_item_main_info_btns_text: {
    marginTop: -2,
    ..._v.fs_fontStyle({
      fz: 18,
      st: 2,
      ds: 1
    })
  },
  mine_item_main_info_tag: {
    scale: 0.3,
    marginRight: 5,
    // marginBottom: 5,
    position: "rightBottom"
  },
  mine_item_tag: {
    position: "rightTop",
    marginRight: 15,
    marginTop: 15,
    width: 60,
    height: 20,
    visible: false
  },
  market_item_tag_bg: {
    background: "#000",
    opacity: 0.6,
    position: "center",
    width: 65,
    height: 20,
    borderRadius: 30
  },
  mine_item_tag_main: {
    borderRadius: 30,
    fontSize: 13,
    fontFamily: "pFont",
    width: "100%",
    height: 20,
    textAlign: "center",
    position: "center"
  },
  market_info: {
    position: "centerTop",
    marginTop: 65,
    scale: 0.28,
    marginLeft: 135,
    width: 40,
    height: 40
  }
};